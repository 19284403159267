import { Hidden, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { Coordinates } from '@soilsense/shared';
import CustomDialog from 'components/Dialog';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFarmStore, useObservationSiteStore } from '../../dataHandlers/RootStore';
import type { AreaId } from '../../interfaces/Area';
import type { IFarm } from '../../interfaces/IFarm';
import { DEFAULT_ZOOM } from '../../utils/consts';
import useTraceUpdate from '../../utils/useTraceUpdate';
import type { IControls } from '../Board/config';
import MapWrapper from '../Map/MapWrapper';
import MarkersMap from '../Map/MarkersMap';

type StyleProps = Readonly<{
  loading: boolean;
  hidden: boolean;
}>;

type Props = StyleProps &
  Readonly<{
    controls: IControls;
    selectedFarm: IFarm;
    selectedArea: AreaId | undefined;
    setSelectedArea: React.Dispatch<AreaId | undefined>;
    forceShowSelection: () => void;
    overviewCards: JSX.Element;
  }>;

const SensorOverview: FC<Props> = observer((props) => {
  useTraceUpdate(props, 'SensorOverview');

  const { hidden, loading, selectedFarm, selectedArea, setSelectedArea, forceShowSelection, overviewCards } =
    props;

  const farmStore = useFarmStore();
  const observationSiteStore = useObservationSiteStore();

  const [mapCenter, setMapCenter] = useState<Coordinates>();
  useEffect(() => {
    setMapCenter(
      selectedFarm?.farmCenterOverride ??
        getMinMaxCenter(observationSiteStore.activeSiteDetails.map((each) => each.site.coordinates))
    );
  }, [selectedFarm.farmCenterOverride, observationSiteStore.activeSiteDetails]);
  useEffect(() => {
    if (selectedArea?.kind == 'site') {
      const siteInfo = observationSiteStore.getSiteInfo(selectedArea.siteId);
      if (siteInfo != undefined) {
        setMapCenter(siteInfo.site.coordinates);
      }
    } else if (selectedArea?.kind == 'field') {
      const field = farmStore.selectedFarmFields.find((each) => each.id == selectedArea.fieldId);
      if (field?.center != undefined) {
        setMapCenter(field.center);
      }
    }
  }, [farmStore.selectedFarmFields, observationSiteStore, selectedArea]);

  const [currentZoomLevel, setCurrentZoomLevel] = React.useState(selectedFarm.mapZoom ?? DEFAULT_ZOOM);
  React.useEffect(() => {
    // if there are any observation sites, set the zoom level to the farm zoom level
    if (observationSiteStore.activeSiteDetails.length > 0) {
      setCurrentZoomLevel(selectedFarm.mapZoom ?? DEFAULT_ZOOM);
    }
  }, [selectedFarm, observationSiteStore.activeSiteDetails]);

  const mapStyle = useMemo(
    () => ({
      opacity: loading ? 0.2 : 1.0,
      display: hidden ? 'none' : 'flex',
      border: '8px solid #eeeeee',
      width: '100%',
      height: '100%',
    }),
    [loading, hidden]
  );

  const [open, setOpen] = useState(false);
  const handleSiteClick = useCallback(
    (siteId: string) => {
      setSelectedArea({ kind: 'site', siteId });
      setOpen(true);
    },
    [setSelectedArea]
  );
  const handleFieldClick = useCallback(
    (fieldId: string) => {
      setSelectedArea({ kind: 'field', fieldId });
      setOpen(true);
    },
    [setSelectedArea]
  );

  const fullScreenDialog = useMediaQuery(useTheme().breakpoints.down('md'));

  console.log({ open });

  return (
    <>
      <MapWrapper style={mapStyle}>
        <MarkersMap
          siteDetails={observationSiteStore.activeSiteDetails}
          borderRadius={0}
          zoomControl={true}
          searchBoxVisible={true}
          center={mapCenter}
          zoom={currentZoomLevel}
          onZoomChange={setCurrentZoomLevel}
          showNdviLayer={false}
          selectedArea={selectedArea}
          setSelectedArea={setSelectedArea}
          handleSiteClick={handleSiteClick}
          handleFieldClick={handleFieldClick}
        />
      </MapWrapper>
      <Hidden mdUp>
        {open && (
          <CustomDialog
            open
            handleClose={() => setOpen(false)}
            fullScreen={fullScreenDialog}
            TransitionProps={{ onEntered: forceShowSelection }}
          >
            {overviewCards}
          </CustomDialog>
        )}
      </Hidden>
    </>
  );
});

function getMinMaxCenter(positions: readonly Coordinates[]): Coordinates {
  const x = positions.map((p) => p?.lat).filter((v) => v);
  const xMin = Math.min(...x);
  const xMax = Math.max(...x);
  const y = positions.map((p) => p.lng);
  const yMin = Math.min(...y);
  const yMax = Math.max(...y);
  return {
    lat: xMin + (xMax - xMin) / 2,
    lng: yMin + (yMax - yMin) / 2,
  };
}

export default SensorOverview;
