import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import Joyride, { type CallBackProps, type Placement } from 'react-joyride';

interface Props {
  run: boolean;
  onFinish: () => void;
}

const NoDevicesTour: React.FC<Props> = ({ run, onFinish }) => {
  const intl = useIntl();
  const theme = useTheme();

  const steps = [
    {
      target: '#settings-icon',
      content: intl.formatMessage({
        id: 'no_devices_tour_message',
        defaultMessage: 'Go to the "Devices" tab of the settings to add your first datalogger',
      }),
      disableBeacon: true,
      placement: 'bottom' as Placement,
      spotlightClicks: true,
    },
  ];

  const handleCallback = (data: CallBackProps) => {
    if (['finished', 'skipped'].includes(data.status)) {
      onFinish();
    }
  };

  return (
    <Joyride
      steps={steps}
      run={run}
      continuous={false}
      showSkipButton={true}
      callback={handleCallback}
      styles={{
        options: {
          primaryColor: theme.palette.primary.main,
        },
      }}
      locale={{
        close: intl.formatMessage({ id: 'close', defaultMessage: 'Close' }),
      }}
    />
  );
};

export default NoDevicesTour;
