import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button, Typography, useMediaQuery } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import { makeStyles, useTheme } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import React, { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import boardBackgroundNoSensors from '../assets/board_background_no_sensors.jpg';
import topBarBackground from '../assets/login_background_narrow.jpg';
import logo from '../assets/soilsense.png';
import BarWithDrawer from '../components/BarWithDrawer';
import {
  BAR_HEIGHT,
  BOTTOM_NAVIGATION_HEIGHT,
  CONTROLS_DEFAULT,
  CONTROL_OPTIONS,
} from '../components/Board/config';
import NoFarmPermissionsDialog from '../components/Dialogs/NoFarmPermissionsDialog';
import SetupDialog from '../components/Dialogs/SetupDialog';
import DrawerContent from '../components/DrawerContent';
import AITab from '../components/LLM/AITab';
import { AITermsDialog } from '../components/LLM/AITermsDialog';
import { useAIState } from '../components/LLM/hooks/useAIState';
import Loading from '../components/Loading';
import { MagicDialogProvider } from '../components/MagicDialogContext';
import BottomNavigationPanel from '../components/Navigation/BottomNavigationPanel';
import { TabLabels } from '../components/Navigation/Config';
import NoDevicesTour from '../components/NoDevicesTour';
import PersistentDrawerLeft from '../components/PersistentDrawerLeft';
import SensorData from '../components/SensorData/SensorData';
import SensorOverview from '../components/SensorData/SensorOverview';
import { showErrorSnackBar, showSuccessSnackBar } from '../components/SnackBar';
import Spacer from '../components/Spacer';
import TopBar from '../components/TopBar';
import { useCurrentUser, useFarmStore, useObservationSiteStore, useUserStore } from '../dataHandlers/RootStore';
import type { AreaId } from '../interfaces/Area';
import theme from '../theme';
import delayRendering from '../utils/delayRendering';
import formatWithFallback from '../utils/formatWithFallback';
import googleAnalyticsInstance from '../utils/googleAnalytics';
import { useLocalStorage } from '../utils/useLocalStorage';
import useTraceUpdate from '../utils/useTraceUpdate';

const useStyles = makeStyles((theme) => ({
  boardRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: `calc(${100}% - ${BAR_HEIGHT}px)`,
    marginTop: BAR_HEIGHT,
    [theme.breakpoints.down('md')]: {
      height: `calc(${100}% - ${BAR_HEIGHT + BOTTOM_NAVIGATION_HEIGHT}px)`,
    },
  },
  confirmButton: {
    width: '50%',
  },
  confirmButtonContainer: {
    width: '100%',
    textAlign: 'end',
    marginBottom: 10,
  },
  noSensorsMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px',
    padding: '5px',
    maxWidth: '450px',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  },
}));

const DEFAULT_TAB = TabLabels.Overview;

const BoardPage: FC = observer((props) => {
  useTraceUpdate(props, 'Board');

  const classes = useStyles();
  const intl = useIntl();

  const [forceUiReactionLoading, setForceUiReactionLoading] = useState(false);
  const [selectedTabString, setSelectedTab] = useLocalStorage('SoilSense.selectedTab', DEFAULT_TAB.toString());
  const selectedTab = Number(selectedTabString);
  const [selectedArea, setSelectedArea] = React.useState<AreaId>();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [showNoPermissionsDialog, setShowNoPermissionsDialog] = useState(false);
  const [showSetupDialog, setShowSetupDialog] = useState(false);

  const observationSiteStore = useObservationSiteStore();
  const hasAnySiteData = observationSiteStore.activeSiteIdentifiers.length > 0;
  const [tourCompleted, setTourCompleted] = React.useState(false);
  const hasNoDevices = observationSiteStore.activeSiteIdentifiers.length === 0;

  const farmStore = useFarmStore();
  const userStore = useUserStore();
  const user = useCurrentUser()!;
  const isFarmAdmin = Boolean(farmStore.isAdmin || userStore.currentUser?.isAdmin);
  const shouldShowTour =
    hasNoDevices && !tourCompleted && !showNoPermissionsDialog && !showSetupDialog && isFarmAdmin;

  const [showAiTermsDialog, setShowAiTermsDialog] = useState(false);

  const tabLabels = useMemo(() => {
    const labels = [TabLabels.Overview.toString(), TabLabels.Charts.toString()];

    if (user.showAiTab === true || user.email?.endsWith('@soilsense.io')) {
      labels.push(TabLabels.AI.toString());
    }

    return labels;
  }, [user.showAiTab, user.email]);

  useEffect(() => {
    if (
      !user.acceptedAiTerms &&
      selectedTab === TabLabels.AI &&
      (user.showAiTab === true || user.email?.endsWith('@soilsense.io'))
    ) {
      setShowAiTermsDialog(true);
    }
    if (selectedTab !== TabLabels.AI) {
      setShowAiTermsDialog(false);
    }
  }, [user.acceptedAiTerms, selectedTab, user.showAiTab, user.email]);

  const { availableFarms, selectedFarm } = farmStore;

  const uiReactionLoading = forceUiReactionLoading || farmStore.isLoading;

  // FIXME move state into stores
  const [controls, setControls] = useState(CONTROLS_DEFAULT);
  React.useEffect(() => {
    observationSiteStore.setShowArchivedSites(controls[CONTROL_OPTIONS.ArchivedLocations]);
  }, [controls, observationSiteStore]);

  const controlsOnChange = (key: CONTROL_OPTIONS) => {
    delayRendering(() => {
      setControls({
        ...controls,
        [key]: !controls[key],
      });
    }, setForceUiReactionLoading);
  };

  const handleTabChange = useCallback(
    (newValue: number | string) => {
      if (newValue === selectedTab) {
        return;
      }

      delayRendering(() => {
        if (typeof newValue === 'string') {
          const index = tabLabels.indexOf(newValue);
          setSelectedTab(index.toString());
          return;
        }
        setSelectedTab(newValue.toString());
      }, setForceUiReactionLoading);
    },
    [selectedTab, setSelectedTab, tabLabels]
  );

  useEffect(() => {
    if (selectedTab != null) {
      googleAnalyticsInstance.pageView(`/${tabLabels[selectedTab]}`);
    }
  }, [selectedTab, tabLabels]);

  const updateAvailableFarms = useCallback(() => {
    farmStore.updateAvailableFarms();
  }, [farmStore]);

  const setSelectedFarmId = useCallback(
    (id: string) => {
      farmStore.setSelectedFarmId(id);
    },
    [farmStore]
  );

  const errorMessage = farmStore.errorMessage;

  console.log('farm store', farmStore.isLoading, farmStore.errorMessage);

  const noSensorsAssignedMessage =
    farmStore.isLoading == false && observationSiteStore.activeSiteIdentifiers.length == 0 ? (
      <div className={classes.noSensorsMessage}>
        <Typography variant='body2' style={{ color: '#5d5d5d', textAlign: 'center' }}>
          <FormattedMessage
            id='ready_to_provide_insights'
            defaultMessage="We are warming up our algorithms to provide you with actionable insights. Let's grow beautiful, healthy crops together and save some water and time in the process"
          />
        </Typography>
        <Typography variant='h5' style={{ color: '#5d5d5d', textAlign: 'center' }}>
          <FormattedMessage id='no_dataloggers_added' defaultMessage='No dataloggers added to the farm yet' />
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            margin: '0 auto',
            width: '100%',
          }}
        >
          <Button
            variant='contained'
            color='primary'
            onClick={() => setTourCompleted(false)}
            startIcon={<AddCircleOutlineIcon />}
            disabled={!isFarmAdmin}
          >
            <FormattedMessage id='add_first_datalogger' defaultMessage='Add your first datalogger' />
          </Button>
          {!isFarmAdmin && (
            <Typography
              variant='body2'
              style={{
                color: '#6f6f6f',
                textAlign: 'center',
                fontSize: '0.6rem',
                width: '100%',
                marginTop: '-10px',
              }}
            >
              <FormattedMessage
                id='add_first_datalogger_admin_only'
                defaultMessage='You must be a farm administrator to add a datalogger'
              />
            </Typography>
          )}
        </div>
      </div>
    ) : undefined;

  const [forcedShowSelectionCounter, setForcedShowSelectionCounter] = useState(0);

  const [aiState, aiActions] = useAIState();

  const mobileDrawer = (
    <DrawerContent
      selectedTab={selectedTab}
      handleTabChange={handleTabChange}
      tabLabels={tabLabels}
      selectedArea={selectedArea}
      setSelectedArea={setSelectedArea}
      forcedShowSelectionCounter={forcedShowSelectionCounter}
      uiReactionLoading={uiReactionLoading}
      controls={controls}
      controlsOnChange={controlsOnChange}
      disabled={Boolean(errorMessage)}
      aiState={aiState}
      aiActions={aiActions}
      noSensorsMessage={noSensorsAssignedMessage}
      hasSiteData={Boolean(hasAnySiteData)}
      version='mobile'
    />
  );
  const desktopDrawer = (
    <DrawerContent
      selectedTab={selectedTab}
      handleTabChange={handleTabChange}
      tabLabels={tabLabels}
      selectedArea={selectedArea}
      setSelectedArea={setSelectedArea}
      forcedShowSelectionCounter={forcedShowSelectionCounter}
      uiReactionLoading={uiReactionLoading}
      controls={controls}
      controlsOnChange={controlsOnChange}
      disabled={Boolean(errorMessage)}
      aiState={aiState}
      aiActions={aiActions}
      noSensorsMessage={noSensorsAssignedMessage}
      hasSiteData={Boolean(hasAnySiteData)}
      version='desktop'
    />
  );
  const bothDrawer = (
    <DrawerContent
      selectedTab={selectedTab}
      handleTabChange={handleTabChange}
      tabLabels={tabLabels}
      selectedArea={selectedArea}
      setSelectedArea={setSelectedArea}
      forcedShowSelectionCounter={forcedShowSelectionCounter}
      uiReactionLoading={uiReactionLoading}
      controls={controls}
      controlsOnChange={controlsOnChange}
      disabled={Boolean(errorMessage)}
      aiState={aiState}
      aiActions={aiActions}
      noSensorsMessage={noSensorsAssignedMessage}
      hasSiteData={Boolean(hasAnySiteData)}
      version='both'
    />
  );

  useEffect(() => {
    const shouldShowNoPermissionsDialog = user.farmPermissions?.length === 0;
    const timer = setTimeout(
      () => {
        setShowNoPermissionsDialog(shouldShowNoPermissionsDialog);
      },
      shouldShowNoPermissionsDialog ? 100 : 1000
    );

    return () => clearTimeout(timer);
  }, [user.farmPermissions?.length]);

  useEffect(() => {
    if (!showSetupDialog) {
      const isFarmAdmin = farmStore.isAdmin;
      setShowSetupDialog(Boolean(user.showSetupDialog) && farmStore.selectedFarm != null && isFarmAdmin);
    }
  }, [user.showSetupDialog, farmStore.selectedFarm, showSetupDialog, farmStore.isAdmin]);

  const handleAcceptAiTerms = useCallback(async () => {
    try {
      await userStore.updateUserSettings({ acceptedAiTerms: true });
      await userStore.doUpdateUserSettings(userStore.currentUser!.uid, { acceptedAiTerms: true });
      setShowAiTermsDialog(false);
      showSuccessSnackBar(intl.formatMessage({ id: 'ai_terms_accepted' }));
    } catch (error) {
      console.error(error);
      showErrorSnackBar(intl.formatMessage({ id: 'error_accepting_ai_terms' }));
    }
  }, [userStore, intl]);

  const handleDeclineAiTerms = useCallback(() => {
    setShowAiTermsDialog(false);
    handleTabChange(TabLabels.Overview);
  }, [handleTabChange]);

  return (
    <MagicDialogProvider>
      <AITermsDialog open={showAiTermsDialog} onAccept={handleAcceptAiTerms} onDecline={handleDeclineAiTerms} />
      <NoDevicesTour run={shouldShowTour} onFinish={() => setTourCompleted(true)} />
      <div className={classes.boardRoot}>
        <TopBar
          backgroundImage={topBarBackground}
          farmName={selectedFarm?.name}
          farms={availableFarms}
          updateAvailableFarms={updateAvailableFarms}
          selectedFarm={selectedFarm}
          setSelectedFarmId={setSelectedFarmId}
          titleImage={logo}
          topSpacing={0}
          zIndex={5}
          titleSize={useMediaQuery(useTheme().breakpoints.down('md')) ? 'h6' : 'h5'}
        />
        <Hidden mdUp>
          {selectedTab == TabLabels.Charts && (
            <BarWithDrawer
              id={0}
              topSpacing={BAR_HEIGHT}
              zIndex={4}
              titleElement={<FormattedMessage id='controls' />}
              canOpen={true}
              appBarClassName='appBarWhite'
              drawerContent={mobileDrawer}
            />
          )}
          {selectedTab == TabLabels.AI && (
            <BarWithDrawer
              id={0}
              topSpacing={BAR_HEIGHT}
              zIndex={4}
              titleElement={<FormattedMessage id='controls' />}
              canOpen={true}
              appBarClassName='appBarWhite'
              drawerContent={mobileDrawer}
            />
          )}
        </Hidden>
        <div style={{ display: 'flex', height: '100%', width: '100%' }}>
          <PersistentDrawerLeft id={0} open drawerContent={desktopDrawer}>
            <Hidden mdUp>
              {selectedTab == TabLabels.Charts && ( // spacer needed when the Controls menu bar is visible
                <Spacer height={BAR_HEIGHT} />
              )}
            </Hidden>
            {uiReactionLoading && <Loading showAsTopBar />}
            {errorMessage ? (
              <>
                <h2 style={{ color: '#5d5d5d', textAlign: 'center', paddingTop: '40px' }}>
                  <FormattedMessage
                    id='something_went_wrong'
                    defaultMessage='Something went wrong, sorry'
                    values={{ error: formatWithFallback(intl, undefined, errorMessage) }}
                  />
                </h2>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '15px',
                    marginTop: '-10px',
                  }}
                >
                  <span style={{ color: '#5d5d5d', textAlign: 'center' }}>
                    <FormattedMessage
                      id='try_refreshing_the_page'
                      defaultMessage='Try refreshing the page. If the problem persists, please contact support at'
                    />{' '}
                    <a href='mailto:support@soilsense.io'>support@soilsense.io</a>
                  </span>
                  <Button variant='contained' color='primary' onClick={() => window.location.reload()}>
                    <FormattedMessage id='refresh' defaultMessage='Refresh' />
                  </Button>
                </div>
              </>
            ) : (
              selectedFarm && (
                <>
                  {!noSensorsAssignedMessage ? (
                    <SensorData
                      hidden={selectedTab !== TabLabels.Charts}
                      loading={uiReactionLoading}
                      controls={controls}
                      country={selectedFarm.country}
                    />
                  ) : (
                    <>
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundImage: `url(${boardBackgroundNoSensors})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          opacity: 0.6,
                          backdropFilter: 'brightness(1.2)',
                          mixBlendMode: 'multiply',
                          display: noSensorsAssignedMessage && selectedTab === TabLabels.Charts ? 'block' : 'none',
                        }}
                      />
                      <div
                        style={{
                          display: noSensorsAssignedMessage && selectedTab === TabLabels.Charts ? 'flex' : 'none',
                          marginTop: '20px',
                          backgroundColor: '#ffffff',
                          borderRadius: '10px',
                          padding: '20px',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                          position: 'absolute',
                          top: '35%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: isMobile ? '90%' : 'fit-content',
                          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        {noSensorsAssignedMessage}
                      </div>
                    </>
                  )}
                  <SensorOverview
                    hidden={selectedTab !== TabLabels.Overview}
                    controls={controls}
                    loading={uiReactionLoading}
                    selectedFarm={selectedFarm}
                    selectedArea={selectedArea}
                    setSelectedArea={setSelectedArea}
                    forceShowSelection={() => setForcedShowSelectionCounter((value) => value + 1)}
                    overviewCards={bothDrawer}
                  />
                  {(user.showAiTab === true || user.email?.endsWith('@soilsense.io')) && (
                    <AITab hidden={selectedTab !== TabLabels.AI} aiState={aiState} aiActions={aiActions} />
                  )}
                </>
              )
            )}
          </PersistentDrawerLeft>
        </div>
        <Hidden mdUp>
          <BottomNavigationPanel
            disabled={false}
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
            labels={tabLabels}
          />
        </Hidden>
      </div>

      {/* <GlobalMagicDialogProvider>{user.isAdmin && <FloatingMagicButton />}</GlobalMagicDialogProvider> */}

      <NoFarmPermissionsDialog isOpen={showNoPermissionsDialog} />
      <SetupDialog isOpen={Boolean(showSetupDialog)} onClose={() => setShowSetupDialog(false)} />
    </MagicDialogProvider>
  );
});

export default BoardPage;
