import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  IconButton,
  Typography,
} from '@mui/material';
import { useUserStore } from 'dataHandlers/RootStore';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import fieldImg from '../assets/field.jpg';
import { FLAGS, LANGUAGE_LABELS, LOCALES } from '../i18n/locales';
import useIsMobile from '../utils/useIsMobile';

interface Props {
  open: boolean;
  onClose: () => void;
}

const LanguageDialog: React.FC<Props> = ({ open, onClose }) => {
  const intl = useIntl();
  const userStore = useUserStore();
  const isMobile = useIsMobile();

  const handleLanguageSelect = (language: LOCALES) => {
    userStore.updateLocale(language);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          margin: '16px',
          width: isMobile ? 'calc(100% - 32px)' : '400px',
          maxWidth: '100%',
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, ml: 1, paddingRight: '96px' }}>
        <FormattedMessage id='select_language' />
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ paddingBottom: '30px' }}>
        <div style={{ display: 'flex', gap: '20px', flexDirection: isMobile ? 'column' : 'row' }}>
          <img
            src={fieldImg}
            alt='Field'
            loading='lazy'
            style={{
              marginTop: 0,
              height: '205px',
              width: '205px',
              objectFit: 'cover',
              borderRadius: '12px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
              <FormattedMessage id='select_language_description' />
            </Typography>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '8px',
                width: '100%',
              }}
            >
              {Object.values(LOCALES).map((locale) => (
                <Button
                  key={locale}
                  onClick={() => handleLanguageSelect(locale)}
                  variant='outlined'
                  sx={{
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '10px',
                    padding: '4px 12px',
                    height: '32px',
                    minWidth: 'auto',
                  }}
                >
                  <Icon sx={{ display: 'flex', alignItems: 'center' }}>{FLAGS[locale]}</Icon>
                  <Typography>{LANGUAGE_LABELS[locale]}</Typography>
                </Button>
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ display: 'none' }} />
    </Dialog>
  );
};

export default LanguageDialog;
