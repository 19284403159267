import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useFarmStore } from '../../dataHandlers/RootStore';

interface SiteSelectorProps {
  selectedSiteId: string;
  setSelectedSiteId: (siteId: string) => void;
}

const SiteSelector: React.FC<SiteSelectorProps> = ({ selectedSiteId, setSelectedSiteId }) => {
  const farmStore = useFarmStore();

  const sites = React.useMemo(() => {
    return farmStore.areas
      .flatMap((area) =>
        area.kind === 'field' ? area.fieldDetails.siteDetails : area.kind === 'site' ? [area.siteDetails] : []
      )
      .map((siteDetails) => ({
        id: siteDetails.site.id,
        name: siteDetails.site.name,
      }));
  }, [farmStore.areas]);

  return (
    <FormControl fullWidth sx={{ mt: 2 }}>
      <InputLabel>Select Site</InputLabel>
      <Select
        value=''
        label='Select Site'
        onChange={(e) => {
          setSelectedSiteId(e.target.value);
        }}
      >
        {sites.map((site) => (
          <MenuItem key={site.id} value={site.id}>
            {site.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SiteSelector;
