import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { FaSignOutAlt } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { useUserStore } from '../dataHandlers/RootStore';

const useStyles = makeStyles((theme) => ({
  logOutButton: {
    color: '#ccc',
    [theme.breakpoints.down('md')]: {
      minWidth: 20,
      padding: 0,
    },
    '&:hover': {
      color: 'white',
    },
  },
}));

const LogOut = ({ style }: { style?: React.CSSProperties }): JSX.Element => {
  const classes = useStyles();

  const userStore = useUserStore();

  return (
    <Button
      className={classes.logOutButton}
      style={style}
      color='inherit'
      startIcon={<FaSignOutAlt />}
      onClick={async () => {
        await userStore.logOut();
      }}
    >
      {/* <Hidden smDown> */}
      <FormattedMessage id='log_out' defaultMessage='Log Out' />
      {/* </Hidden> */}
    </Button>
  );
};

export default LogOut;
