import type { Coordinates } from '@soilsense/shared';
import type { Firestore } from 'firebase/firestore';
import { action, makeObservable } from 'mobx';
import { createContext, useContext } from 'react';
import type { DeviceName } from '../firebase/DeviceNames';
import { Base } from '../firebase/base';
import type { FirebaseTokenProvider, User, UserSettings } from '../interfaces/IUser';
import type { DataLoggerStatusStore } from './DataLoggerStatusStore';
import { FarmStore } from './FarmStore';
import type { GatewayInfoStore } from './GatewayInfoStore';
import type { ObservationSiteStore } from './ObservationSiteStore';
import type { UserUpdate } from './UserStore';
import { UserStore } from './UserStore';

export class RootStore {
  public readonly firebase: Base;
  public readonly firestore: Firestore;
  public readonly userStore: UserStore;
  public readonly farmStore: FarmStore;

  constructor() {
    makeObservable(this);
    this.firebase = new Base((update) => this.setUser(update));
    this.firestore = this.firebase.api.db;
    this.userStore = new UserStore(this.firebase);
    this.farmStore = new FarmStore(this.firebase, this.userStore);
  }

  @action public setUser(user: UserUpdate | null): void {
    const oldUid = this.userStore.currentUser?.uid;
    const oldPermissions = this.userStore.currentUser?.farmPermissions;

    this.userStore.setUser(user);
    if (oldUid != user?.user.uid || oldPermissions?.length != user?.user.farmPermissions?.length) {
      this.farmStore.updateAvailableFarms();
    }
  }

  async fetchAssignableDevice(customerVisibleId: string): Promise<DeviceName | undefined> {
    const sanitizedId = customerVisibleId.trim();
    const deviceNameObject = await this.firebase.deviceNames.getAssociatedDeviceNameObject(sanitizedId);
    if (deviceNameObject === undefined) {
      return undefined;
    }
    const { deviceId, deviceType } = deviceNameObject;
    let canAssign = false;
    if (deviceType === 'sensor' || deviceType === 'nbsensor') {
      canAssign = await this.firebase.sensors.canAssignSensorToFarm(deviceId);
    }
    if (deviceType === 'gateway') {
      canAssign = await this.firebase.gateways.canAssignGatewayToFarm(deviceId);
    }
    return canAssign ? deviceNameObject : undefined;
  }

  async updateGateway(
    gatewayId: string,
    selectedFarmId: string,
    coordinates: Coordinates | undefined
  ): Promise<void> {
    await this.firebase.gateways.updateGateway(gatewayId, selectedFarmId, coordinates);
  }
}

export const rootStore: RootStore = new RootStore();
const rootStoreContext = createContext(rootStore);

export const rootStoreAI: RootStore = new RootStore();
const rootStoreContextAI = createContext(rootStoreAI);

export function useFirebase(): Base {
  return useContext(rootStoreContext).firebase;
}

export function useFirestore(): Firestore {
  return useContext(rootStoreContext).firestore;
}

export function useRootStore(): RootStore {
  return useContext(rootStoreContext);
}

export function useUserStore(): UserStore {
  return useContext(rootStoreContext).userStore;
}

export function useCurrentUser(): User | null | undefined {
  return useContext(rootStoreContext).userStore.currentUser;
}

export function useTokenProvider(): FirebaseTokenProvider | undefined {
  return useContext(rootStoreContext).userStore.currentTokenProvider;
}

export function useUserSettings(): [UserSettings | null | undefined, React.Dispatch<Partial<UserSettings>>] {
  const userStore = useContext(rootStoreContext).userStore;
  return [userStore.currentUser, userStore.updateUserSettings.bind(userStore)];
}

export function useFarmStore(): FarmStore {
  return useContext(rootStoreContext).farmStore;
}

export function useFarmStoreAI(): FarmStore {
  return useContext(rootStoreContextAI).farmStore;
}

export function useObservationSiteStore(): ObservationSiteStore {
  return useContext(rootStoreContext).farmStore.observationSiteStore;
}

export function useObservationSiteStoreAI(): ObservationSiteStore {
  return useContext(rootStoreContextAI).farmStore.observationSiteStore;
}

export function useGatewayInfoStore(): GatewayInfoStore {
  return useContext(rootStoreContext).farmStore.gatewayStatusStore;
}

export function useSensorDataLoading(): boolean {
  const observationSiteStore = useObservationSiteStore();
  return observationSiteStore.isLoading;
}

export function useDataLoggerStatusStore(): DataLoggerStatusStore {
  return useContext(rootStoreContext).farmStore.dataLoggerStatusStore;
}
