import { useMediaQuery, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import type { CONTROL_OPTIONS, IControls } from 'components/Board/config';
import React from 'react';
import { TabLabels } from '../components/Navigation/Config';
import ControlPanelTabs from '../components/Navigation/ControlPanelTabs';
import ChartsControlPanel from '../components/SensorData/ChartsControlPanel';
import { useCurrentUser } from '../dataHandlers/RootStore';
import type { AreaId } from '../interfaces/Area';
import AIControlPanel from './LLM/AIControlPanel';
import type { AIState, AIStateActions } from './LLM/hooks/useAIState';
import OverviewControlPanel from './SensorData/OverviewControlPanel';

interface IProps {
  disabled: boolean;
  selectedTab: number;
  handleTabChange: (newValue: React.ReactText) => void;
  tabLabels: string[];
  selectedArea: AreaId | undefined;
  setSelectedArea: React.Dispatch<AreaId>;
  forcedShowSelectionCounter: number;
  uiReactionLoading: boolean;
  controls: IControls;
  controlsOnChange: (key: CONTROL_OPTIONS) => void;
  aiState: AIState;
  aiActions: AIStateActions;
  noSensorsMessage: React.ReactNode;
  hasSiteData: boolean;
  version: 'mobile' | 'desktop' | 'both';
}

const DrawerContent: React.FC<IProps> = ({
  disabled,
  selectedTab,
  handleTabChange,
  tabLabels,
  selectedArea,
  setSelectedArea,
  forcedShowSelectionCounter,
  uiReactionLoading,
  controls,
  controlsOnChange,
  aiState,
  aiActions,
  noSensorsMessage,
  hasSiteData,
  version,
}) => {
  const currentUser = useCurrentUser()!;
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  if (isMdDown && version === 'desktop') {
    return null;
  }
  if (!isMdDown && version === 'mobile') {
    return null;
  }

  return (
    <>
      <Hidden mdDown implementation='css'>
        <ControlPanelTabs
          disabled={uiReactionLoading || disabled}
          selectedTab={selectedTab}
          handleChange={handleTabChange}
          labels={tabLabels}
        />
      </Hidden>
      {selectedTab === TabLabels.Charts && (
        <ChartsControlPanel
          uiReactionLoading={uiReactionLoading}
          controls={controls}
          controlsOnChange={controlsOnChange}
        />
      )}
      {selectedTab === TabLabels.Overview ? (
        <OverviewControlPanel
          selectedArea={selectedArea}
          setSelectedArea={setSelectedArea}
          forcedShowSelectionCounter={forcedShowSelectionCounter}
          uiReactionLoading={uiReactionLoading}
          noSensorsMessage={noSensorsMessage}
        />
      ) : undefined}
      {(currentUser.showAiTab === true || currentUser.email?.endsWith('@soilsense.io')) &&
        selectedTab === TabLabels.AI && (
          <AIControlPanel aiState={aiState} aiActions={aiActions} hasSiteData={hasSiteData} />
        )}
      <Divider />
    </>
  );
};

export default DrawerContent;
