import SettingsIcon from '@mui/icons-material/Settings';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from '@mui/styles';
import type { ObservationSite } from '@soilsense/shared';
import { DEFAULT_BOT_DEPTH, DEFAULT_TOP_DEPTH, getLastSoilDataSource } from '@soilsense/shared';
import { Alert } from 'components/CancelConfirmButton';
import { customerVisibleDeviceId } from 'dataHandlers/utils/formatters';
import deepEqual from 'deep-equal';
import { archivedObservationSite, extractDisabledRules } from 'interfaces/Farm';
import type { IFarm } from 'interfaces/IFarm';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { showErrorSnackBar, showInfoSnackBar, showSuccessSnackBar } from '../../components/SnackBar';
import {
  useCurrentUser,
  useDataLoggerStatusStore,
  useFarmStore,
  useGatewayInfoStore,
  useObservationSiteStore,
  useRootStore,
  useUserSettings,
  useUserStore,
} from '../../dataHandlers/RootStore';
import googleAnalyticsInstance from '../../utils/googleAnalytics';
import useTraceUpdate from '../../utils/useTraceUpdate';
import DeviceSettingsComponent from './Devices';
import FarmSettingsComponent from './Farm';
import type { GatewayInformation } from './Gateways';
import type { IFarmSettings, ISensorSettings, IUserSettings, IValidatedSensorSettings } from './settingsState';
import {
  determineObservationSiteUpdate,
  farmSettingsFromFirebaseObj,
  sensorSettingsFromSiteInfos,
  userSettingsFromFirebaseObj,
  userSettingsToFirebaseObj,
} from './settingsState';
import runValidations from './validations';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  style?: React.CSSProperties;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  dialogTitleRoot: {
    zIndex: 2,
    padding: 0,
  },
  dialogContentRoot: {
    overflowY: 'scroll',
    padding: 12,

    [theme.breakpoints.up('md')]: {
      height: '80vh',
      width: '600px',
    },

    [theme.breakpoints.down('md')]: {
      padding: 6,
    },
  },
  tabSelected: {
    zIndex: 5,
    borderBottom: '1px solid #ccc',
  },
}));
function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export interface ISettingsState {
  user: IUserSettings;
  farm: IFarmSettings;
}

export type FarmSettingsMutatorFunc = (farm: IFarmSettings) => IFarmSettings;
export type UserSettingsMutatorFunc = (user: UserSettingsMutatorFunc) => UserSettingsMutatorFunc;
export type FarmSettingsUpdateFunc = (mutator: FarmSettingsMutatorFunc) => void;

const registerSettingsOpenInAnalytics = () => {
  googleAnalyticsInstance.modalView('/settings');
};

type Props = Readonly<{
  updateAvailableFarms: () => void;
  selectedFarm: IFarm;
}>;

const SettingsComponent: React.FC<Props> = observer((props) => {
  const { updateAvailableFarms, selectedFarm } = props;
  const selectedFarmId = selectedFarm.id;
  useTraceUpdate(props);

  const intl = useIntl();
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const [userSettings, updateUserSettings] = useUserSettings();

  const [stateSettings, updateStateSettings] = React.useState<ISettingsState>({
    user: userSettingsFromFirebaseObj(userSettings),
    farm: farmSettingsFromFirebaseObj(selectedFarm),
  });

  const gatewayInfoStore = useGatewayInfoStore();
  const firebaseGateways = gatewayInfoStore.gatewayInfosArray;
  const [newGateways, setNewGateways] = React.useState<readonly GatewayInformation[]>([]);
  const gateways = React.useMemo(() => [...firebaseGateways, ...newGateways], [firebaseGateways, newGateways]);

  const rootStore = useRootStore();
  const userStore = useUserStore();
  const farmStore = useFarmStore();
  const observationSiteStore = useObservationSiteStore();

  const [sensorSettingsState, setSensorSettingsState] = React.useState<ISensorSettings>({});

  const currentUser = useCurrentUser();
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const userSettingsUnchanged = deepEqual(stateSettings?.user, userSettingsFromFirebaseObj(userSettings));
  const farmSettingsUnchanged = deepEqual(stateSettings?.farm, farmSettingsFromFirebaseObj(selectedFarm));
  const sensorSettingsUnchanged = deepEqual(
    sensorSettingsState,
    sensorSettingsFromSiteInfos(observationSiteStore.activeSiteDetails)
  );
  const gatewaysUnchanged = newGateways.length == 0;
  const noNewSettings =
    farmSettingsUnchanged && userSettingsUnchanged && sensorSettingsUnchanged && gatewaysUnchanged;

  React.useEffect(() => {
    if (settingsOpen) {
      console.log('observationSiteStore.activeSiteDetails', observationSiteStore.activeSiteDetails);
      setSensorSettingsState(sensorSettingsFromSiteInfos(observationSiteStore.activeSiteDetails));
    }
  }, [observationSiteStore.activeSiteDetails, settingsOpen]);

  React.useEffect(() => {
    if (settingsOpen) {
      updateStateSettings({
        user: userSettingsFromFirebaseObj(userSettings),
        farm: farmSettingsFromFirebaseObj(selectedFarm),
      });
    }
  }, [settingsOpen, userSettings, selectedFarm]);

  const updateSettings = async (
    validatedSensorSettings: IValidatedSensorSettings,
    farmCallback?: () => void,
    sensorCallback?: () => void
  ) => {
    if (!userSettingsUnchanged && currentUser?.uid) {
      userStore
        .doUpdateUserSettings(currentUser?.uid, stateSettings?.user)
        .then(() => {
          // showSuccessSnackBar('User Settings Updated Successfully');
          showSuccessSnackBar(intl.formatMessage({ id: 'user_settings_updated_successfully' }));
          updateUserSettings(userSettingsToFirebaseObj(stateSettings?.user));
        })
        .catch((error) => {
          // showErrorSnackBar(`Could not update user settings: ${error}`);
          showErrorSnackBar(
            intl.formatMessage({ id: 'could_not_update_user_settings' }, { error: error as string })
          );
        });
    }
    if (!farmSettingsUnchanged && selectedFarmId) {
      try {
        await farmStore.updateFarmSettings(selectedFarmId, stateSettings?.farm);
        showSuccessSnackBar(intl.formatMessage({ id: 'farm_settings_updated_successfully' }));
        if (farmCallback) {
          farmCallback();
        }
      } catch (error) {
        showErrorSnackBar(
          intl.formatMessage({ id: 'could_not_update_farm_settings' }, { error: error as string })
        );
        throw error; // Re-throw the error to prevent further processing
      }
    }
    if (!sensorSettingsUnchanged) {
      const { observationSites, observationSiteOrder } = selectedFarm;
      const now = Date.now();
      const updates: [string, ObservationSite][] = [];
      const processedDevices: Set<string> = new Set();

      if (observationSites != undefined && observationSiteOrder != undefined) {
        for (const siteId of observationSiteOrder) {
          const site = observationSites[siteId];
          const lastSoilDataSource = getLastSoilDataSource(site);
          if (site == null || lastSoilDataSource == null) {
            continue;
          }
          const { deviceNumber } = lastSoilDataSource;
          const deviceId = deviceNumber.toString();
          if (deviceId in validatedSensorSettings) {
            const settings = validatedSensorSettings[deviceId];
            if (settings.moveDevice) {
              updates.push([siteId, archivedObservationSite(site, now)]);
            } else {
              updates.push([siteId, determineObservationSiteUpdate(site, lastSoilDataSource, settings, now)]);
              processedDevices.add(deviceId);
            }
          }
        }
      }

      for (const sensorId in validatedSensorSettings) {
        const settings = validatedSensorSettings[sensorId];
        if (processedDevices.has(sensorId) === false) {
          const disabledRules = extractDisabledRules(settings);
          const {
            name,
            location,
            pawStressThreshold,
            pawOverIrrigationThreshold,
            vwcStressThreshold,
            vwcOverIrrigationThreshold,
            topDepth,
            midDepth,
            botDepth,
            cropType,
            locationType,
          } = settings;

          // FIXME this is a weird check - in practice it should always
          // evaluate to true due to the location being collected from the
          // user by the add device wizard
          if (location != undefined) {
            updates.push([
              farmStore.generateNewId(),
              {
                name: name ?? '',
                disabledRules,
                safeRanges: {
                  plantAvailableWater:
                    pawStressThreshold != undefined && pawOverIrrigationThreshold != undefined
                      ? [pawStressThreshold, pawOverIrrigationThreshold]
                      : undefined,
                  volumetricWaterContent:
                    vwcStressThreshold != undefined && vwcOverIrrigationThreshold != undefined
                      ? [vwcStressThreshold, vwcOverIrrigationThreshold]
                      : undefined,
                },
                coordinates: location,
                cropType,
                soilDataSourceHistory: [
                  {
                    startTimestamp: now,
                    value: {
                      deviceNumber: Number(sensorId),
                      deviceName: settings.deviceName,
                      configuration: {
                        cableBottom: {
                          depth: botDepth ?? DEFAULT_BOT_DEPTH,
                        },
                        cableMiddle:
                          midDepth == undefined || midDepth == 'disabled' ? undefined : { depth: midDepth },
                        cableTop: {
                          depth: topDepth ?? DEFAULT_TOP_DEPTH,
                        },
                        placement: 'vertical',
                        topAndBottomCablesSwapped: false,
                      },
                    },
                  },
                ],
                locationType: locationType,
              },
            ]);
          }
        }
      }

      try {
        await farmStore.updateObservationSites(updates);
        showSuccessSnackBar(intl.formatMessage({ id: 'sensor_information_updated_successfully' }));
        if (sensorCallback) {
          sensorCallback();
        }
      } catch (error) {
        showErrorSnackBar(
          intl.formatMessage({ id: 'could_not_update_sensor_information' }, { error: error as string })
        );
      }
    }

    if (!gatewaysUnchanged) {
      const allGatewayPromises = newGateways.map(async (gw) => {
        const customerVisibleId = customerVisibleDeviceId(gw);
        try {
          await rootStore.updateGateway(gw.id, selectedFarmId, gw.coordinates);
          // showSuccessSnackBar(`Gateway with ID ${customerVisibleId} updated successfully`);
          showSuccessSnackBar(
            intl.formatMessage({ id: 'gateway_updated_successfully' }, { id: customerVisibleId })
          );
        } catch (error) {
          showErrorSnackBar(intl.formatMessage({ id: 'could_not_update_gateway' }, { id: customerVisibleId }));
        }
      });
      await Promise.all(allGatewayPromises);
    }

    if (noNewSettings) {
      showInfoSnackBar(intl.formatMessage({ id: 'no_settings_updated' }));
    }
  };

  const [isSaving, setIsSaving] = React.useState(false);

  const handleSettingsSave = async (closeSettings: boolean = true) => {
    try {
      setIsSaving(true);
      const validatedSensorSettings = runValidations(stateSettings, sensorSettingsState, intl);
      if (validatedSensorSettings) {
        if (closeSettings) {
          setSettingsOpen(false);
        }
        let farmCallback;
        let sensorCallback;
        if (!farmSettingsUnchanged) {
          farmCallback = updateAvailableFarms;
        }
        if (!sensorSettingsUnchanged) {
          sensorCallback = () => {
            if (
              observationSiteStore.activeSiteIdentifiers
                .map(({ deviceIds: { id } }) => id)
                .sort()
                .join(',') !== Object.keys(sensorSettingsState).sort().join(',') ||
              Object.values(sensorSettingsState).filter((each) => each.moveDevice).length > 0
            ) {
              updateAvailableFarms();
            }
          };
        }
        await updateSettings(validatedSensorSettings, farmCallback, sensorCallback);
        gatewayInfoStore.updateGateways();
        setNewGateways([]);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
      console.error(errorMessage);
      showErrorSnackBar(intl.formatMessage({ id: 'could_not_save_settings' }));
    } finally {
      setTimeout(() => {
        setIsSaving(false);
      }, 1000);
    }
  };

  const updateFarmSettingsState: FarmSettingsUpdateFunc = (func: FarmSettingsMutatorFunc) => {
    updateStateSettings(
      (prev: ISettingsState): ISettingsState => ({
        ...prev,
        farm: func(prev?.farm),
      })
    );
  };

  // const updateUserSettingsState = (func: (user: IUserSettings) => IUserSettings) => {
  //   updateStateSettings((prev: ISettingsState) => ({
  //     ...prev,
  //     user: func(prev?.user),
  //   }));
  // };

  const dataLoggerStatusStore = useDataLoggerStatusStore();
  const refetchStatuses = React.useCallback(() => {
    dataLoggerStatusStore.updateStatuses();
    gatewayInfoStore.update();
  }, [dataLoggerStatusStore, gatewayInfoStore]);

  React.useEffect(() => {
    if (settingsOpen) {
      refetchStatuses();
    }
  }, [refetchStatuses, settingsOpen]);

  const menusDefinition = {
    // User: (
    //   <UserSettingsComponent state={stateSettings.user} updateState={updateUserSettingsState} />
    // ),
    Farm: <FarmSettingsComponent state={stateSettings.farm} updateState={updateFarmSettingsState} />,
    Devices: (
      <DeviceSettingsComponent
        gateways={gateways}
        setGateways={setNewGateways}
        sensorSettingsState={sensorSettingsState}
        setSensorSettingsState={setSensorSettingsState}
        refetchStatuses={refetchStatuses}
        handleSettingsSave={handleSettingsSave}
      />
    ),
  };

  const handleClose = React.useCallback(() => {
    setSettingsOpen(false);
    setNewGateways([]);
  }, [setSettingsOpen, setNewGateways]);

  const [showCancelConfirmationPrompt, setShowCancelConfirmationPrompt] = React.useState(false);
  const onClose = React.useCallback(() => {
    if (noNewSettings) {
      handleClose();
    } else {
      setShowCancelConfirmationPrompt(true);
    }
  }, [handleClose, noNewSettings]);

  return (
    <>
      <IconButton
        color='inherit'
        aria-label='Settings'
        component='span'
        onClick={async () => {
          setSettingsOpen(true);
          registerSettingsOpenInAnalytics();
        }}
        size='large'
        id='settings-icon'
      >
        {settingsOpen ? (
          <SettingsIcon
            style={{
              transform: 'rotate(185deg)',
              transition: 'transform 0.2s ease-in-out',
            }}
          />
        ) : (
          <SettingsIcon style={{ transition: 'transform 0.2s ease-in-out' }} />
        )}
      </IconButton>
      <Dialog
        fullScreen={fullScreen}
        open={settingsOpen}
        onClose={onClose}
        aria-labelledby='settings-dialog'
        scroll={'paper'}
      >
        <DialogTitle
          id='scroll-dialog-title'
          classes={{
            root: classes.dialogTitleRoot,
          }}
        >
          <AppBar position='static'>
            <Tabs
              value={value}
              indicatorColor='primary'
              textColor='inherit'
              onChange={(event, newValue) => {
                if (typeof newValue == 'number') {
                  setValue(newValue);
                }
              }}
              variant='fullWidth'
              aria-label='Settings Tab'
            >
              {Object.keys(menusDefinition).map((tab, i) => (
                <Tab
                  label={intl.formatMessage({ id: tab.toLowerCase() })}
                  {...a11yProps(i)}
                  key={i}
                  classes={{ selected: classes.tabSelected }}
                />
              ))}
            </Tabs>
          </AppBar>
        </DialogTitle>

        {stateSettings && (
          <>
            <DialogContent
              classes={{
                root: classes.dialogContentRoot,
              }}
            >
              {Object.values(menusDefinition).map((element, index) => (
                <TabPanel
                  value={value}
                  index={index}
                  key={index}
                  style={{
                    paddingRight: theme.spacing(2),
                  }}
                >
                  {element}
                </TabPanel>
              ))}
            </DialogContent>
            <DialogActions
              style={{
                borderTop: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Button onClick={onClose} disabled={isSaving}>
                {noNewSettings ? <FormattedMessage id='close' /> : <FormattedMessage id='cancel' />}
              </Button>
              <Button
                onClick={() => handleSettingsSave(true)}
                color='primary'
                disabled={noNewSettings || isSaving}
                startIcon={isSaving ? <CircularProgress size={20} /> : null}
              >
                <FormattedMessage id='save' />
              </Button>
            </DialogActions>
          </>
        )}

        <Alert
          open={showCancelConfirmationPrompt}
          handleYes={() => {
            setShowCancelConfirmationPrompt(false);
            handleClose();
          }}
          handleNo={() => {
            setShowCancelConfirmationPrompt(false);
          }}
        />
      </Dialog>
    </>
  );
});

export default SettingsComponent;
