import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Dialog, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../utils/consts';
import { useFetchWithAuth } from './utils/fetchWithAuth';

const DialogTitleStyled = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

interface FileDialogProps {
  fileId: string | null;
  onClose: () => void;
}

export const FileDialog = ({ fileId, onClose }: FileDialogProps) => {
  const [content, setContent] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const fetchWithAuth = useFetchWithAuth();

  useEffect(() => {
    const fetchContent = async () => {
      if (!fileId) return;

      try {
        setLoading(true);
        setError(null);
        const response = await fetchWithAuth(`${BASE_URL}/llm/files/${fileId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log('Response:', response);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const text = await response.text();
        console.log('Text:', text);
        setContent(text);
        setError(null);
      } catch (err) {
        setError('Failed to load file content');
        setContent(null);
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, [fileId, fetchWithAuth]);

  if (!fileId) return null;

  return (
    <Dialog open={!!fileId} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitleStyled>
        Source File
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitleStyled>
      <Divider />
      <DialogContent>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <CircularProgress />
          </div>
        ) : error ? (
          <div style={{ color: 'red' }}>{error}</div>
        ) : (
          <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{content}</pre>
        )}
        <div
          style={{
            display: 'flex',
            marginTop: 60,
            fontSize: '0.8rem',
            color: 'gray',
          }}
        >
          We don't support previewing the content of the source files yet, but we are working on it!
        </div>
      </DialogContent>
    </Dialog>
  );
};
