import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Button, Chip, InputAdornment, TextField } from '@mui/material';
import { Plus } from 'mdi-material-ui';
import type { ChangeEvent, KeyboardEvent } from 'react';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import useIsMobile from '../../utils/useIsMobile';
import { showInfoSnackBar } from '../SnackBar';

type Props = Readonly<{
  label: string;
  placeholder: string;
  value: readonly string[];
  onAdd: (item: string) => void;
  onDelete: (item: string) => void;
}>;

export const isValidPhoneNumber = /^\+[0-9]*$/;

// add button can be clicked instead of pressing enter
const AddButtonElement = ({
  item,
  onAdd,
  setItem,
}: {
  item: string;
  onAdd: (item: string) => void;
  setItem: (item: string) => void;
}) => {
  const isMobile = useIsMobile();
  const intl = useIntl();
  return (
    <Button
      variant='outlined'
      style={{
        borderColor: '#CCC',
        minWidth: isMobile ? '30px' : '50px',
      }}
      onClick={() => {
        const phoneNumberStripped = item.replace(/\s/g, '');
        if (isValidPhoneNumber.test(phoneNumberStripped)) {
          onAdd(phoneNumberStripped);
          setItem('');
        } else {
          showInfoSnackBar(intl.formatMessage({ id: 'invalid_phone_number' }));
        }
      }}
    >
      <Plus />
    </Button>
  );
};

export function PhoneNumbersInput({ label, placeholder, value, onAdd, onDelete }: Props): JSX.Element {
  const [item, setItem] = useState('');
  const intl = useIntl();

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => setItem(event.target.value), []);
  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key == 'Enter') {
        const phoneNumberStripped = item.replace(/\s/g, '');
        if (isValidPhoneNumber.test(phoneNumberStripped)) {
          onAdd(phoneNumberStripped);
          setItem('');
        } else {
          showInfoSnackBar(intl.formatMessage({ id: 'invalid_phone_number' }));
        }
      }
    },
    [item, onAdd, intl]
  );

  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <TextField
        label={label}
        placeholder={placeholder}
        value={item}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        variant='outlined'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <PhoneIcon />
            </InputAdornment>
          ),
          endAdornment: item ? <AddButtonElement item={item} onAdd={onAdd} setItem={setItem} /> : null,
        }}
        fullWidth
      />
      <Box>
        {value.map((item, index) => (
          <Chip sx={{ m: '3px' }} key={index} label={item} onDelete={() => onDelete(item)} />
        ))}
      </Box>
    </Box>
  );
}
