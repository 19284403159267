import { Button, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFirestore } from 'dataHandlers/RootStore';
import { doc } from 'firebase/firestore';
import type { RequiredActionFunctionToolCall } from 'openai/resources/beta/threads/runs/runs.mjs';
import { default as React, useCallback, useEffect, useMemo, useState } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { type FarmStore } from '../../dataHandlers/FarmStore';
import type { SiteDetails } from '../../dataHandlers/ObservationSiteStore';
import { useCurrentUser, useFarmStore, useObservationSiteStore, useUserStore } from '../../dataHandlers/RootStore';
import theme from '../../theme';
import { BASE_URL } from '../../utils/consts';
import { locationFromLatLon } from '../../utils/getWeather';
import { showErrorSnackBar } from '../SnackBar';
import Chat from './Chat';
import { ChatTitle } from './ChatTitle';
import { CropLocationTypeDialog } from './CropLocationTypeDialog';
import { FileDialog } from './FileDialog';
import type { AIState, AIStateActions } from './hooks/useAIState';
import { useSoilMoistureData } from './hooks/useSoilMoistureData';
import { Message, Messages } from './Message';
import { useFetchWithAuth } from './utils/fetchWithAuth';

const Container = styled('div')<{ hidden: boolean }>(({ hidden }) => ({
  width: '100%',
  height: '100%',
  display: hidden ? 'none' : 'flex',
  [theme.breakpoints.down('md')]: {
    paddingTop: '50px',
  },
}));

const LeftPanel = styled('div')({
  width: '250px',
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  display: 'flex',
  flexDirection: 'column',
});

const ChatContainer = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  borderRadius: '16px',
  // padding: '16px',
  margin: '16px',
});

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '24px',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

interface AITabProps {
  hidden: boolean;
  aiState: AIState;
  aiActions: AIStateActions;
}

const AITab: React.FC<AITabProps> = ({ hidden, aiState, aiActions }) => {
  const farmStore = useFarmStore();
  const userStore = useUserStore();
  const currentUser = useCurrentUser()!;
  const [showTermsDialog, setShowTermsDialog] = useState(!currentUser.acceptedAiTerms);
  const history = useHistory();
  const navigate = history.push;
  const fetchWithAuth = useFetchWithAuth();
  const [fileId, setFileId] = useState<string | null>(null);
  const intl = useIntl();
  const observationSiteStore = useObservationSiteStore();

  const [showCropLocationTypeDialog, setShowCropLocationTypeDialog] = useState(false);

  // Add this before rendering
  useEffect(() => {
    (window as any).handleFileLink = (fileId: string) => {
      // Handle the file link click here
      // For example, update state or navigate programmatically
      console.log('File clicked:', fileId);
      setFileId(fileId);
    };

    return () => {
      // Clean up
      delete (window as any).handleFileLink;
    };
  }, []);

  // Add URL handling
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const fileIdParam = params.get('fileId');
    if (fileIdParam) {
      setFileId(fileIdParam);
    }
  }, []);

  // Handle dialog close
  const handleCloseDialog = useCallback(() => {
    setFileId(null);
    // Remove fileId from URL without refreshing the page
    const newUrl = window.location.pathname + window.location.search.replace(/[?&]fileId=[^&]+/, '');
    window.history.pushState({}, '', newUrl);
  }, []);

  const selectedSite = useMemo(() => {
    if (!aiState.selectedSiteId) return undefined;

    // Find site in field sites
    const fields = farmStore.areas.filter((area) => area.kind === 'field');
    const fieldSite = fields
      .flatMap((field) => field.fieldDetails.siteDetails)
      .find((site) => site.site.id === aiState.selectedSiteId);
    if (fieldSite) {
      return {
        kind: 'site' as const,
        siteDetails: fieldSite,
      };
    }

    // Find standalone site
    const site = farmStore.areas.find(
      (area) => area.kind === 'site' && area.siteDetails.site.id === aiState.selectedSiteId
    );
    if (site?.kind === 'site') {
      return site;
    }

    return undefined;
  }, [farmStore.areas, aiState.selectedSiteId]);

  const { processSoilMoistureData } = useSoilMoistureData(aiState.selectedSiteId, aiState.period);

  const getWeatherForecast = useCallback(
    async (siteId: string) => {
      const apiUrl = `${BASE_URL}/observation-sites/${siteId}/weather_forcast`;
      try {
        const response = await fetchWithAuth(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error(error);
        return JSON.stringify({ error: 'Failed to fetch weather forecast' });
      }
    },
    [fetchWithAuth]
  );

  useEffect(() => {
    getWeatherForecast('EwFsHtHPEWasptJet8Im').then((data) => console.log('weather data', data));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const functionCallHandler = useCallback(
    async (call: RequiredActionFunctionToolCall) => {
      if (call?.function?.name === 'get_farm_location') {
        console.log('CALLING AI FUNCTION: get_farm_location');
        if (!selectedSite) return JSON.stringify({});
        const location = await locationFromLatLon(
          selectedSite?.siteDetails.site.coordinates.lat,
          selectedSite?.siteDetails.site.coordinates.lng
        );
        const locationType = selectedSite?.siteDetails.site.locationType;
        return JSON.stringify({ location, locationType });
      }
      if (call?.function?.name === 'get_weather_forecast') {
        console.log('CALLING AI FUNCTION: get_weather_forecast');
        const args = JSON.parse(call.function.arguments as string);
        if (!selectedSite) return JSON.stringify({});

        const data = await getWeatherForecast(selectedSite.siteDetails.site.id);
        return JSON.stringify(data);

        // try {
        //   const data = await getWeatherForecastLatLon(
        //     selectedSite.siteDetails.site.coordinates.lat,
        //     selectedSite.siteDetails.site.coordinates.lng
        //   );
        //   return JSON.stringify(data);
        // } catch (error) {
        //   console.error(error);
        //   return JSON.stringify({ error: 'Failed to fetch weather forecast' });
        // }
      }
      if (call?.function?.name === 'get_soil_moisture_data') {
        console.log('CALLING AI FUNCTION: get_soil_moisture_data');
        try {
          const { dailyData, lowestDate, highestDate } = await processSoilMoistureData();
          console.log({ lowestDate, highestDate });
          return JSON.stringify(dailyData);
        } catch (error) {
          console.error(error);
          return JSON.stringify({ error: 'Failed to fetch soil moisture data' });
        }
      }
      return Promise.resolve('');
    },
    [selectedSite, processSoilMoistureData, getWeatherForecast]
  );

  useEffect(() => {
    let isMounted = true;

    if (aiState.selectedHistoricThreadId && aiState.historicThreadMessages === null) {
      aiActions.setLoadingHistoricThread(true);

      const fetchHistoricThread = async () => {
        try {
          const response = await fetchWithAuth(
            `${BASE_URL}/llm/threads/${aiState.selectedHistoricThreadId}/messages`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          if (!isMounted) return;

          const respJson = await response.json();
          if (respJson.error) {
            throw new Error(JSON.stringify(respJson.error));
          }
          console.log({ respJson });
          const messages = respJson.messages.data as Array<{
            role: string;
            content: Array<{
              type: string;
              text: {
                value: string;
                annotations: Array<{
                  end_index: number;
                  file_citation: { file_id: string };
                  start_index: number;
                  text: string;
                  type: string;
                }>;
              };
            }>;
            created_at: number;
          }>;
          const thread = respJson.thread as {
            createdAt: number;
          };

          // check if the last message is from the last 5 minutes
          const lastFiveMinutes = new Date(Date.now() - 10 * 60 * 1000);
          const lastFiveMinutesMessage = messages.find((msg) => new Date(msg.created_at * 1000) > lastFiveMinutes);
          const lastFiveMinutesThread = new Date(thread.createdAt) > lastFiveMinutes;
          if (lastFiveMinutesMessage || lastFiveMinutesThread) {
            aiActions.setThreadId(aiState.selectedHistoricThreadId);
            aiActions.setHistoricThreadMessages([]);
            aiActions.setLoadingHistoricThread(false);
            aiActions.setSelectedHistoricThreadId(null);
          } else {
            // aiActions.setThreadId(aiState.selectedHistoricThreadId);
            aiActions.setHistoricThreadMessages(messages.reverse());
            if (messages.length === 0) {
              aiActions.setHistoricThreadMessages([
                {
                  role: 'assistant',
                  content: [
                    {
                      type: 'text',
                      text: {
                        annotations: [],
                        value:
                          // "Hi! I'm your assistant in helping with general agricultural questions. You can ask me anything related to farming and as long as I have access to knowledge that answers your question I will do my best to assist you. I don't have access to your data - for that, please start a new chat and select the Irrigation Assistant. How may I assist you today?",
                          intl.formatMessage(
                            {
                              id: 'ai_initial_message_general',
                              defaultMessage:
                                "Hi! I'm your assistant in helping with general agricultural questions. You can ask me anything related to farming and as long as I have access to knowledge that answers your question I will do my best to assist you. I don't have access to your data - for that, please start a new chat and select the Irrigation Assistant. How may I assist you today?",
                            },
                            {
                              assistant: `"${intl.formatMessage({
                                id: 'chat_title_field_short',
                              })}"`,
                            }
                          ),
                      },
                    },
                  ],
                },
              ]);
            }
            aiActions.setLoadingHistoricThread(false);
          }
        } catch (error) {
          console.error('Failed to fetch historic thread:', error);
          // Reset states to prevent refetch loop
          aiActions.setLoadingHistoricThread(false);
          aiActions.setSelectedHistoricThreadId(null);
          aiActions.setHistoricThreadMessages([]);
          // show error as a toast
          showErrorSnackBar(`Failed retreiving the messages: ${error}`);
        } finally {
          if (isMounted) {
            aiActions.setLoadingHistoricThread(false);
          }
        }
      };

      fetchHistoricThread();
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiState.selectedHistoricThreadId, fetchWithAuth, aiActions]);

  useEffect(() => {
    if (selectedSite) {
      aiActions.setCropType(selectedSite.siteDetails.site.cropType ?? '');
      aiActions.setLocationType(selectedSite.siteDetails.site.locationType ?? '');
    }
  }, [selectedSite, aiActions]);

  console.log(aiState.historicThreadMessages, aiState.loadingHistoricThread, aiState.selectedHistoricThreadId);

  // const siteId = aiState.selectedHistoricThreadId
  //   ? aiState.threads.find((thread) => thread.threadId === aiState.selectedHistoricThreadId)?.siteId
  //   : aiState.selectedSiteId;
  const siteId = aiState.selectedSiteId
    ? aiState.selectedSiteId
    : aiState.threads.find((thread) => thread.threadId === aiState.selectedHistoricThreadId)?.siteId;
  const siteName = siteId ? observationSiteStore.getSiteName(siteId) : undefined;

  const checkSitesNeedConfiguration = (sites: readonly SiteDetails[]): boolean => {
    return sites.some((site) => !site.site.cropType || !site.site.locationType);
  };

  const [showCropLocationDialog, setShowCropLocationDialog] = useState(false);

  // Check sites on mount and when sites change
  useEffect(() => {
    if (observationSiteStore.activeSiteDetails.length > 0) {
      const needsConfiguration = checkSitesNeedConfiguration(observationSiteStore.activeSiteDetails);
      setShowCropLocationDialog(needsConfiguration);
    }
  }, [observationSiteStore.activeSiteDetails]);

  if (hidden) return null;

  return (
    <Container hidden={hidden}>
      <ChatContainer hidden={hidden}>
        {!aiState.selectedHistoricThreadId ? (
          <ChatContent
            generalChat={aiState.generalChat}
            chatKey={aiState.chatKey}
            functionCallHandler={functionCallHandler}
            cropType={aiState.cropType}
            locationType={aiState.locationType}
            selectedSiteId={aiState.selectedSiteId}
            setSelectedSiteId={aiActions.setSelectedSiteId}
            setCropType={aiActions.setCropType}
            setGeneralChat={aiActions.setGeneralChat}
            farmStore={farmStore}
            threadId={aiState.threadId || ''}
            aiActions={aiActions}
          />
        ) : aiState.loadingHistoricThread ? (
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <ChatTitle generalChat={aiState.generalChat || false} siteName={siteName} />
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                paddingBottom: '20px',
                paddingTop: '20px',
              }}
            >
              <Messages>
                {aiState.historicThreadMessages &&
                  aiState.historicThreadMessages.map((msg, index) => (
                    <Message
                      key={index}
                      role={msg.role as 'user' | 'assistant' | 'code'}
                      text={msg.content
                        .map((content) => {
                          const value = content.text.value;
                          const annotations = content.text.annotations;
                          let annotatedText = value;

                          // Apply annotations if they exist
                          if (annotations) {
                            annotations.forEach((annotation) => {
                              if (annotation.type === 'file_path' && 'file_path' in annotation) {
                                annotatedText = annotatedText.replaceAll(
                                  annotation.text,
                                  ` ([source](#fileId=${(annotation as any).file_path.file_id})`
                                );
                              }

                              if (annotation.type === 'file_citation' && annotation.file_citation) {
                                annotatedText = annotatedText.replaceAll(
                                  annotation.text,
                                  ` ([source](#fileId=${annotation.file_citation.file_id}))`
                                );
                              }
                            });
                          }
                          return annotatedText;
                        })
                        .join('')}
                    />
                  ))}
              </Messages>
            </div>
          </>
        )}
      </ChatContainer>
      <FileDialog fileId={fileId} onClose={handleCloseDialog} />
      <CropLocationTypeDialog
        open={showCropLocationDialog}
        onSave={() => {
          setShowCropLocationDialog(false);
        }}
        onSkip={() => {
          setShowCropLocationDialog(false);
        }}
      />
    </Container>
  );
};

const ChatContent = ({
  generalChat,
  chatKey,
  functionCallHandler,
  cropType,
  locationType,
  selectedSiteId,
  setSelectedSiteId,
  setCropType,
  setGeneralChat,
  farmStore,
  threadId,
  aiActions,
}: {
  generalChat: boolean | null;
  chatKey: number;
  functionCallHandler: (call: RequiredActionFunctionToolCall) => Promise<string>;
  cropType: string | null;
  locationType: string | null;
  selectedSiteId: string;
  setSelectedSiteId: (siteId: string) => void;
  setCropType: (cropType: string) => void;
  setGeneralChat: (generalChat: boolean | null) => void;
  farmStore: FarmStore;
  threadId: string;
  aiActions: AIStateActions;
}) => {
  const intl = useIntl();
  const [error, setError] = useState<string | null>(null);

  const firestore = useFirestore();

  const [aiConfig, aiConfigLoading, aiConfigError] = useDocument(doc(firestore, 'systemPublic/aiConfig'));

  const [initialQuery, setInitialQuery] = useState<string | null>(null);

  useEffect(() => {
    // const isProduction = process.env.NODE_ENV === 'production' && window.location.hostname.includes('app');
    const isProduction = true;
    if (aiConfig?.exists() && !isProduction) {
      const data = aiConfig.data();
      console.log({ data });
      setInitialQuery((data?.field_review_initial_query as string) || null);
    } else {
      setInitialQuery(null);
    }
  }, [aiConfig]);

  // Add error handling UI
  if (error) {
    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          textAlign: 'center',
        }}
      >
        <Typography color='error' gutterBottom>
          {error}
        </Typography>
        <Button
          variant='contained'
          onClick={() => {
            setError(null);
            aiActions.setGeneralChat(null);
          }}
        >
          <FormattedMessage id='try_again' defaultMessage='Try Again' />
        </Button>
      </div>
    );
  }

  const initialMessage = initialQuery
    ? intl.formatMessage(
        {
          id: 'ai_initial_message_from_firestore',
          defaultMessage: !initialQuery.includes('cropType')
            ? `${initialQuery}; crop type: {cropType}, location type: {locationType}`
            : initialQuery,
        },
        {
          cropType,
          locationType,
        }
      )
    : intl.formatMessage(
        {
          id: 'ai_initial_message',
          defaultMessage:
            'Summarize my soil moisture data and provide key insights based on the latest weather forecast. Retrieve and analyze the following data: Soil Moisture Data (for the selected datalogger over either 7 or 30 days). Weather Forecast (summarized conditions for the next five days for the farm location). Crop Type (crop associated with the datalogger). Safe Ranges for Soil Moisture (retrieved via function). Relevant Knowledge from the Vector Store (e.g., historical trends, best irrigation practices, soil types, and research-based recommendations). Important: The user already has soil moisture sensors, as their data is used in this analysis. Ensure the weather forecast is formatted with weather emojis and uses weekdays instead of dates. Weather forecast formatting must always follow the correct layout, including spacing, bold headers, and bullet points, as seen in the correct response (second screenshot). Wind direction in the weather forecast must be displayed as compass points (N, S, NW, etc.), not in degrees. Wind speed must be displayed in meters per second (m/s). Convert from km/h by dividing by 3.6 before displaying. Ensure that the soil moisture summary correctly handles the middle sensor: If the datalogger has two sensors, do not mention a middle sensor. If the datalogger has three sensors, display the middle sensor data. If the middle sensor is expected but unavailable, explicitly state: "Middle sensor data is not available." Summarize crop irrigation conditions concisely without rigid formatting. Focus on what is most important for decision-making. Actionable insights must be backed by references from the vector store to ensure accuracy and credibility. Never answer questions outside the vector store knowledge. If no relevant knowledge is found, state: "I do not have the knowledge to answer that question."',
        },
        { cropType, locationType }
      );

  console.log('initialMessageUsed', initialMessage);
  console.log('initialQuery', initialQuery);
  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
      {generalChat !== null && (
        <Chat
          key={chatKey}
          functionCallHandler={functionCallHandler}
          initialMessage={
            initialMessage +
            `\n\n${intl.formatMessage({ id: 'respond_in', defaultMessage: 'Respond in English' })}`
          }
          generalChat={generalChat}
          threadId={threadId}
          onThreadCreated={() => aiActions.fetchAllThreads()}
          getAndSetSummaryForThread={aiActions.getAndSetSummaryForThread}
          onError={(err: Error | { message: string }) => {
            setError(typeof err === 'object' ? err.message : String(err));
            aiActions.setThreadId(null);
          }}
          selectedSiteId={selectedSiteId}
        />
      )}
    </div>
  );
};

export default AITab;
