import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Link,
  Typography,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

interface AITermsDialogProps {
  open: boolean;
  onAccept: () => void;
  onDecline: () => void;
}

export const AITermsDialog: React.FC<AITermsDialogProps> = ({ open, onAccept, onDecline }) => {
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const intl = useIntl();

  return (
    <Dialog open={open} maxWidth='md'>
      <DialogTitle>{intl.formatMessage({ id: 'ai_terms_dialog_title' })}</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography paragraph>{intl.formatMessage({ id: 'ai_terms_dialog_description' })}</Typography>
        <Typography paragraph>{intl.formatMessage({ id: 'ai_terms_dialog_optional' })}</Typography>

        <Typography variant='h6' sx={{ mt: 2 }}>
          {intl.formatMessage({ id: 'ai_terms_dialog_data_policy_title' })}
        </Typography>
        <Typography paragraph>
          {intl.formatMessage({ id: 'ai_terms_dialog_data_policy_text' })}{' '}
          <Link href='https://soilsense.io/privacy-policy' target='_blank' rel='noopener'>
            {intl.formatMessage({ id: 'privacy_policy' })}
          </Link>
          .
        </Typography>

        <Typography variant='h6' sx={{ mt: 2 }}>
          {intl.formatMessage({ id: 'ai_terms_dialog_terms_title' })}
        </Typography>
        <Typography paragraph>{intl.formatMessage({ id: 'ai_terms_dialog_terms_text' })}</Typography>

        <FormControlLabel
          control={<Checkbox checked={termsAccepted} onChange={(e) => setTermsAccepted(e.target.checked)} />}
          label={
            <Typography variant='body2'>
              {intl.formatMessage({ id: 'ai_terms_dialog_checkbox_text' })}{' '}
              <Link href='https://soilsense.io/terms-and-conditions#ai' target='_blank' rel='noopener'>
                {intl.formatMessage({ id: 'ai_terms_dialog_terms_link_text' })}
              </Link>
            </Typography>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onDecline}>{intl.formatMessage({ id: 'ai_terms_dialog_back_button' })}</Button>
        <Button variant='contained' color='primary' onClick={onAccept} disabled={!termsAccepted}>
          {intl.formatMessage({ id: 'ai_terms_dialog_enable_button' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
