import {
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Routes } from '../Routes';
import LanguageDialog from './LanguageDialog';
import Logo, { LogoColor } from './Logo';
import Progress from './Progress';

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      // Removing overflow: 'hidden' to allow scrolling on mobile
    },
    body: {
      backgroundColor: theme.palette.common.white,
      minHeight: '100vh', // Ensure body takes at least full height
    },
    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
      {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitTextFillColor: theme.palette.text.primary,
        WebkitBoxShadow: '0 0 0px 1000px transparent inset',
      },
  },
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4), // Add bottom margin for better spacing
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgb(255,255,255,0.65)',
    padding: 20,
    borderRadius: 10,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  glowingText: {
    textShadow: `0 0 5px #fff`,
  },
}));

export interface ISignupForm {
  elements: {
    email: {
      value: string;
    };
    password: {
      value: string;
    };
    confirmPassword: {
      value: string;
    };
    newsletter: {
      checked: boolean;
    };
    termsAndConditions: {
      checked: boolean;
    };
  };
}

interface IProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement & ISignupForm>) => void;
  loading?: boolean;
  prefilledEmail?: string;
}

const SignUp: React.FunctionComponent<IProps> = ({ onSubmit, loading, prefilledEmail }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [emailHasBeenPrefilled, setEmailHasBeenPrefilled] = useState(Boolean(prefilledEmail));
  const [showTermsError, setShowTermsError] = useState(false);
  const [showLanguageDialog, setShowLanguageDialog] = React.useState(false);

  React.useEffect(() => {
    const hasSelectedLanguage = localStorage.getItem('locale');
    const defaultLocaleSet = localStorage.getItem('defaultLocaleSet');
    if (!hasSelectedLanguage || defaultLocaleSet === 'true') {
      setShowLanguageDialog(true);
    }
  }, []);

  const handleLanguageSelect = () => {
    setShowLanguageDialog(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement & ISignupForm>) => {
    const form = event.target as HTMLFormElement & ISignupForm;
    const termsAccepted = form.elements.termsAndConditions.checked;

    if (!termsAccepted) {
      event.preventDefault();
      setShowTermsError(true);
      return;
    }

    setShowTermsError(false);
    onSubmit(event);
  };

  useEffect(() => {
    if (prefilledEmail) {
      setEmailHasBeenPrefilled(true);
    }
  }, [prefilledEmail]);

  return (
    <Container
      component='main'
      maxWidth='xs'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        py: 2, // Add vertical padding
        minHeight: { xs: 'calc(100vh - 32px)', sm: 'auto' }, // Adjust height for mobile
      }}
    >
      <CssBaseline />
      <LanguageDialog open={showLanguageDialog} onClose={handleLanguageSelect} />
      <Box className={classes.paper} boxShadow={3}>
        <Logo color={LogoColor.red} width={100} />
        <Typography
          component='h1'
          variant='h5'
          style={{
            paddingTop: 10,
            fontWeight: 'bold',
            // textTransform: 'uppercase',
            color: 'rgba(0, 0, 0, 0.7)',
          }}
        >
          <FormattedMessage id='welcome_to_soilsense' />
          {/* Welcome to SoilSense */}
        </Typography>
        <Typography
          variant='body2'
          style={{ color: 'rgba(0, 0, 0, 0.7)', textAlign: 'center', paddingLeft: 20, paddingRight: 20 }}
        >
          {/* Let's make your crops stronger and irrigation easier, together. */}
          <FormattedMessage id='signup_welcome_message' />
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='email'
                label={intl.formatMessage({ id: 'email_address' })}
                name='email'
                autoComplete='email'
                defaultValue={prefilledEmail}
                disabled={emailHasBeenPrefilled}
              />
              {emailHasBeenPrefilled && (
                <Typography
                  variant='caption'
                  color='textSecondary'
                  sx={{
                    mt: 1,
                    display: 'block',
                    fontSize: '0.75rem',
                    lineHeight: '1.1',
                  }}
                >
                  {intl.formatMessage({ id: 'signup_email_prefilled' })}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                name='password'
                label={intl.formatMessage({ id: 'password' })}
                type='password'
                id='password'
                autoComplete='new-password'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                name='confirmPassword'
                label={intl.formatMessage({ id: 'confirm_password' })}
                type='password'
                id='confirmPassword'
                autoComplete='new-password'
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox name='newsletter' id='newsletter' color='primary' />}
                label={
                  <Typography variant='body2' style={{ fontSize: '0.8rem' }}>
                    <FormattedMessage id='signup_newsletter_consent' />
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: -10 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='termsAndConditions'
                    id='termsAndConditions'
                    color='primary'
                    onChange={() => setShowTermsError(false)}
                  />
                }
                label={
                  <Typography variant='body2' style={{ fontSize: '0.8rem' }}>
                    <FormattedMessage
                      id='signup_terms_consent'
                      values={{
                        terms: (chunks: React.ReactNode) => (
                          <Link
                            href='https://soilsense.io/terms-and-conditions'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {chunks}
                          </Link>
                        ),
                        policy: (chunks: React.ReactNode) => (
                          <Link
                            href='https://soilsense.io/privacy-policy'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {chunks}
                          </Link>
                        ),
                      }}
                    />
                  </Typography>
                }
              />
              {showTermsError && (
                <Typography
                  color='error'
                  variant='caption'
                  sx={{
                    display: 'block',
                    mt: 0.5,
                    ml: 2,
                  }}
                >
                  <FormattedMessage id='terms_and_conditions_required' />
                </Typography>
              )}
            </Grid>
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled={loading}
          >
            <FormattedMessage id='sign_up' />
            <Box mx={1.5} display='flex' alignItems='center'>
              {loading ? <Progress size={20} /> : undefined}
            </Box>
          </Button>
          <Grid container justifyContent='flex-end'>
            <Grid item>
              <Link href={Routes.LOGIN} variant='body2' className={classes.glowingText} underline='hover'>
                <FormattedMessage id='signup_already_have_account_sign_in' />
              </Link>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default SignUp;
