import { useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import type { FC } from 'react';
import React from 'react';
import ReactImageAppear from 'react-image-appear';
import loginBackgroundImage from '../assets/login_background.jpg';
import loginBackgroundSmallImage from '../assets/login_background_small.jpg';
import { LanguagePicker } from './TopBar';

const useStyles = makeStyles((theme) => ({
  box: {
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: -1,
    width: '100%',
    height: '100%',
  },
  img: {
    minWidth: '100vw',
    minHeight: '100vh',
    objectFit: 'cover',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.primary.main,
    opacity: 0.6,
    mixBlendMode: 'luminosity',
  },
  container: {
    position: 'relative',
    minHeight: '100vh',
    overflowY: 'auto',
  },
}));

interface IProps {
  children: React.ReactNode;
  highResolutionBackground: string;
  lowResolutionBackground: string;
}

const BackgroundOverlayContainer: React.FC<IProps> = ({
  children,
  highResolutionBackground,
  lowResolutionBackground,
}) => {
  const classes = useStyles();
  const background = useMediaQuery(useTheme().breakpoints.up('md'), { noSsr: true })
    ? highResolutionBackground
    : lowResolutionBackground;
  return (
    <div data-testid='background-overlay-container' className={classes.container}>
      {children}
      <div className={classes.box}>
        <ReactImageAppear className={classes.img} src={background} placeholder={undefined} showLoader={false} />
      </div>
    </div>
  );
};

export const SoilsenseBackgroundOverlay: FC = React.memo(({ children }) => (
  <BackgroundOverlayContainer
    highResolutionBackground={loginBackgroundImage}
    lowResolutionBackground={loginBackgroundSmallImage}
  >
    <div
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        padding: 10,
        zIndex: 1000,
      }}
    >
      <LanguagePicker />
    </div>

    <div style={{ paddingBottom: 32 }}>{children}</div>
  </BackgroundOverlayContainer>
));

export default BackgroundOverlayContainer;
