import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import React, { useCallback, useEffect, useState } from 'react';
import { useFirestore } from '../../dataHandlers/RootStore';
import { BASE_URL } from '../../utils/consts';
import type { Thread } from '../LLM/hooks/useAIState';
import { Message, Messages } from '../LLM/Message';
import { useFetchWithAuth } from '../LLM/utils/fetchWithAuth';

type Message = {
  role: string;
  content: Array<{
    type: string;
    text: {
      value: string;
      annotations: Array<{
        end_index: number;
        file_citation: { file_id: string };
        start_index: number;
        text: string;
        type: string;
      }>;
    };
  }>;
  created_at: number;
};

const ChatContentDialog = ({
  threadId,
  open,
  onClose,
}: {
  threadId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fetchWithAuth = useFetchWithAuth();

  const fetchMessages = useCallback(async () => {
    setLoading(true);
    const response = await fetchWithAuth(`${BASE_URL}/admin/threads/${threadId}/messages`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const respJson = await response.json();

    if (respJson.error) {
      throw new Error(JSON.stringify(respJson.error));
    }

    const messages = respJson.messages.data as Array<{
      role: string;
      content: Array<{
        type: string;
        text: {
          value: string;
          annotations: Array<{
            end_index: number;
            file_citation: { file_id: string };
            start_index: number;
            text: string;
            type: string;
          }>;
        };
      }>;
      created_at: number;
    }>;
    const thread = respJson.thread as {
      createdAt: number;
    };

    setMessages(messages.reverse());
    setLoading(false);
  }, [threadId, fetchWithAuth]);

  useEffect(() => {
    if (open) {
      fetchMessages();
    }
  }, [fetchMessages, open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Chat Content</DialogTitle>
      <DialogContent>
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          <Messages>
            {messages.map((msg, index) => (
              <Message
                key={index}
                role={msg.role as 'user' | 'assistant' | 'code'}
                text={msg.content
                  .map((content) => {
                    const value = content.text.value;
                    const annotations = content.text.annotations;
                    let annotatedText = value;

                    // Apply annotations if they exist
                    if (annotations) {
                      annotations.forEach((annotation) => {
                        if (annotation.type === 'file_path' && 'file_path' in annotation) {
                          annotatedText = annotatedText.replaceAll(
                            annotation.text,
                            ` ([source](#fileId=${(annotation as any).file_path.file_id})`
                          );
                        }

                        if (annotation.type === 'file_citation' && annotation.file_citation) {
                          annotatedText = annotatedText.replaceAll(
                            annotation.text,
                            ` ([source](#fileId=${annotation.file_citation.file_id}))`
                          );
                        }
                      });
                    }
                    return annotatedText;
                  })
                  .join('')}
              />
            ))}
          </Messages>
        )}
      </DialogContent>
    </Dialog>
  );
};

const AdminChatHistories = () => {
  const firestore = useFirestore();
  const [users, setUsers] = useState<string[]>([]);
  const [userIdToEmailMap, setUserIdToEmailMap] = useState<Record<string, string>>({});
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [showSoilsenseOnly, setShowSoilsenseOnly] = useState<boolean>(false);

  const [threads, setThreads] = useState<Thread[]>([]);
  const [allThreadsLoading, setAllThreadsLoading] = useState(false);
  const fetchWithAuth = useFetchWithAuth();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedThreadId, setSelectedThreadId] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const loadMore = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
  }, []);

  const fetchLatestThreads = useCallback(async () => {
    setAllThreadsLoading(true);
    try {
      const response = await fetchWithAuth(`${BASE_URL}/admin/threads?page=${page}&limit=${limit}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const threadsData = await response.json();
      if (threadsData.error) {
        throw new Error(JSON.stringify(threadsData.error));
      }
      setThreads((prevThreads) => (page === 1 ? threadsData.threads : [...prevThreads, ...threadsData.threads]));
      setAllThreadsLoading(false);
      const userIds = threadsData.threads.map((thread: Thread) => thread.userId) as string[];
      const uniqueUserIds = Array.from(new Set(userIds));
      setUsers((prevUsers) => [...prevUsers, ...uniqueUserIds]);
    } catch (error) {
      console.error('Error fetching threads', error);
      setAllThreadsLoading(false);
    }
  }, [fetchWithAuth, page, limit]);

  useEffect(() => {
    const fetchUserEmails = async () => {
      const userEmails = await Promise.all(
        users.map(async (userId) => {
          const userDoc = await getDoc(doc(firestore, 'users', userId));
          return userDoc.data()?.email;
        })
      );
      setUserIdToEmailMap(
        userEmails.reduce<Record<string, string>>((acc, email, index) => {
          acc[users[index]] = email;
          return acc;
        }, {})
      );
    };
    fetchUserEmails();
  }, [users, firestore]);

  useEffect(() => {
    fetchLatestThreads();
  }, [fetchLatestThreads]);

  const handleOpenDialog = (threadId: string) => {
    setSelectedThreadId(threadId);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedThreadId(null);
  };

  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <label style={{ marginRight: '20px' }}>
          <input
            type='radio'
            value='all'
            checked={!showSoilsenseOnly}
            onChange={() => setShowSoilsenseOnly(false)}
          />
          Show All Users
        </label>
        <label>
          <input
            type='radio'
            value='soilsense'
            checked={showSoilsenseOnly}
            onChange={() => setShowSoilsenseOnly(true)}
          />
          Don't Show Soilsense Users
        </label>
      </div>

      {threads.length > 0 && (
        <div>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Chat Type</th>
                <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Summary</th>
                <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>User Email</th>
                <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Site ID</th>
                <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {threads
                .filter((thread) => {
                  const userEmail = userIdToEmailMap[thread.userId];
                  return !showSoilsenseOnly || (userEmail && !userEmail.endsWith('@soilsense.io'));
                })
                .map((thread) => (
                  <tr key={thread.threadId}>
                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{thread.chatType}</td>
                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{thread.summary}</td>
                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                      {userIdToEmailMap[thread.userId]}
                    </td>
                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{thread.siteId}</td>
                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                      <button onClick={() => handleOpenDialog(thread.threadId)}>View Chat</button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <button onClick={loadMore} disabled={allThreadsLoading}>
            {allThreadsLoading ? 'Loading...' : 'Load More'}
          </button>
        </div>
      )}
      {allThreadsLoading && <div>Loading...</div>}
      {selectedThreadId && (
        <ChatContentDialog threadId={selectedThreadId} open={dialogOpen} onClose={handleCloseDialog} />
      )}
    </>
  );
};

export default AdminChatHistories;
