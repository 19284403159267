import { Person } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, List, ListItem, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import type { FC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useUserStore } from '../../dataHandlers/RootStore';
import useIsMobile from '../../utils/useIsMobile';
import { showInfoSnackBar } from '../SnackBar';
import type { FarmSettingsUpdateFunc } from './index';
import {
  CustomList,
  FarmName,
  InstantNotificationChannel,
  WhatsappNotifications,
  WhatsappNumbers,
} from './inputComponents';
import type { IFarmSettings } from './settingsState';

type FarmUser = {
  uid?: string;
  email?: string | null;
  isAdmin?: boolean;
  isFarmAdmin: boolean;
};

const FarmSettingsComponent: FC<{
  state: IFarmSettings;
  updateState: FarmSettingsUpdateFunc;
}> = ({ state, updateState }) => {
  const intl = useIntl();
  const [newEmail, setNewEmail] = useState('');
  const [isAddingReader, setIsAddingReader] = useState(false);
  const [newUsers, setNewUsers] = useState<string[]>([]);
  const [emailError, setEmailError] = useState<string | null>(null);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleAddReader = async (email: string) => {
    if (!validateEmail(email)) {
      setEmailError(
        intl.formatMessage({
          id: 'invalid_email_format',
          defaultMessage: 'Please enter a valid email address',
        })
      );
      return;
    }

    // check if email is already in the list
    if (state.readerInfos.some((reader) => reader.email === email)) {
      // print warning message
      showInfoSnackBar(
        intl.formatMessage({
          id: 'reader_already_exists',
          defaultMessage: 'Reader with this email already exists',
        })
      );
      return;
    }
    updateState((prev) => ({
      ...prev,
      readers: [...(prev.readers ?? []), email],
      readerInfos: [...(prev.readerInfos ?? []), { id: 'invited', email, isAdmin: false }],
    }));
    setNewUsers((prev) => [...prev, email]);
    setNewEmail('');
    setEmailError(null);
    setIsAddingReader(false);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setNewEmail(value);
    setEmailError(null);
  };

  return (
    <>
      <CustomList
        // title='General'
        title={intl.formatMessage({ id: 'settings_general' })}
      >
        <FarmName value={state.farmName} update={updateState} />
        {/* <CountrySelector value={state.country} update={updateState} /> */}
      </CustomList>
      <CustomList
        // title='Notifications'
        title={intl.formatMessage({ id: 'settings_notifications' })}
      >
        <WhatsappNotifications value={state.enableWhatsappNotif} update={updateState} />
        {state.enableWhatsappNotif && <WhatsappNumbers value={state.phoneNumbers} update={updateState} />}
        {state.enableWhatsappNotif && (
          <InstantNotificationChannel value={state.instantNotificationChannel} update={updateState} />
        )}
      </CustomList>
      <CustomList
        title={intl.formatMessage({ id: 'settings_users' })}
        action={
          isAddingReader ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                fontVariantCaps: 'normal',
                fontSize: '0.7rem',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            >
              <TextField
                size='small'
                style={{
                  fontSize: '0.7rem',
                  color: 'rgba(0, 0, 0, 0.6)',
                }}
                value={newEmail}
                onChange={handleEmailChange}
                placeholder={intl.formatMessage({ id: 'enter_reader_email', defaultMessage: 'Email' })}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && newEmail) {
                    handleAddReader(newEmail);
                  }
                }}
                error={!!emailError}
                helperText={emailError}
                autoFocus
                type='email'
              />
              <IconButton
                onClick={() => handleAddReader(newEmail)}
                color='primary'
                disabled={!newEmail}
                sx={{
                  backgroundColor: 'rgba(25, 118, 210, 0.04)',
                  '&:hover': {
                    backgroundColor: 'rgba(25, 118, 210, 0.1)',
                  },
                }}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setIsAddingReader(false);
                  setNewEmail('');
                  setEmailError(null);
                }}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          ) : (
            <Button
              startIcon={<AddIcon />}
              onClick={() => setIsAddingReader(true)}
              variant='text'
              sx={{ textTransform: 'none' }}
            >
              {intl.formatMessage({ id: 'add_user', defaultMessage: 'Add reader' })}
            </Button>
          )
        }
      >
        <FarmReaders value={state.readerInfos ?? []} update={updateState} newUsers={newUsers} />
      </CustomList>
    </>
  );
};

export const FarmReaders: React.FC<{
  value: readonly { id: string; email: string; isAdmin?: boolean }[];
  update: FarmSettingsUpdateFunc;
  newUsers: string[];
}> = ({ value, update, newUsers }) => {
  const intl = useIntl();
  const userStore = useUserStore();
  const [deletedUsers, setDeletedUsers] = useState<string[]>([]);
  const isMobile = useIsMobile();

  const filteredReaders = useMemo(() => {
    const f1 = value.filter((reader) => {
      const isSoilsenseEmail = reader.email.endsWith('@soilsense.io');
      const isCurrentUserSoilsense = userStore.currentUser?.email?.endsWith('@soilsense.io');

      if (isCurrentUserSoilsense) return true;

      return !isSoilsenseEmail;
    });

    // remove duplicates
    return f1.filter((reader, index, self) => self.findIndex((t) => t.email === reader.email) === index);
  }, [value, userStore.currentUser]);

  const [currentUserInfo, setCurrentUserInfo] = useState<FarmUser | null>(null);

  useEffect(() => {
    setCurrentUserInfo({
      uid: userStore.currentUser?.uid,
      email: userStore.currentUser?.email,
      isAdmin: userStore.currentUser?.isAdmin,
      isFarmAdmin: filteredReaders.find((reader) => reader.id === userStore.currentUser?.uid)?.isAdmin ?? false,
    });
  }, [filteredReaders, userStore.currentUser]);

  const handleRemoveReader = (readerEmail: string) => {
    const readerId = filteredReaders.find((reader) => reader.email === readerEmail)?.id;
    setDeletedUsers((prev) => [...prev, readerEmail]);
    update((prev) => ({
      ...prev,
      readers: prev.readers.filter((id) => id !== readerId),
      readerInfos: prev.readerInfos.filter((info) => info.email !== readerEmail),
    }));
  };

  const handleRoleChange = (readerEmail: string, isAdmin: boolean) => {
    update((prev) => ({
      ...prev,
      readerInfos: prev.readerInfos.map((info) => (info.email === readerEmail ? { ...info, isAdmin } : info)),
    }));
  };

  return (
    <List
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        borderRadius: 1,
        '& .MuiListItem-root': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          '&:last-child': {
            borderBottom: 'none',
          },
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          alignItems: {
            xs: 'flex-start',
            sm: 'center',
          },
          gap: {
            xs: 1,
            sm: 0,
          },
          padding: {
            xs: '16px 16px',
            sm: '8px 16px',
          },
        },
      }}
    >
      {filteredReaders.map((reader) => {
        const isNewUser = newUsers.includes(reader.id);
        const invitedMessage = isNewUser
          ? intl.formatMessage({ id: 'will_be_invited_on_save', defaultMessage: '(will be invited on save)' })
          : intl.formatMessage({ id: 'invited', defaultMessage: '(invited)' });

        return (
          <ListItem key={reader.id}>
            <ListItemText
              primary={
                <div style={{ display: 'flex', alignItems: 'center', gap: isMobile ? '0.75rem' : '1rem' }}>
                  <div
                    style={{
                      width: '24px',
                      height: '24px',
                      backgroundColor: '#fff',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                  >
                    <Person sx={{ fontSize: 16 }} />
                  </div>
                  <span
                    style={{
                      fontSize: isMobile ? '0.95rem' : '1rem',
                      overflow: 'auto',
                      whiteSpace: 'nowrap',
                      maxWidth: '300px',
                      display: 'inline-block',
                    }}
                  >
                    {reader.email}
                    {reader.id === 'invited' && (
                      <span style={{ marginLeft: '0.5rem', color: 'rgba(0, 0, 0, 0.6)', fontSize: '0.875em' }}>
                        {invitedMessage}
                      </span>
                    )}
                  </span>
                </div>
              }
              primaryTypographyProps={{
                style: {
                  color: 'rgba(0, 0, 0, 0.7)',
                  fontWeight: reader.email === currentUserInfo?.email ? 'bold' : 'normal',
                  textDecoration: 'none',
                  fontSize: isMobile ? '0.95rem' : '1rem',
                },
              }}
            />
            {(currentUserInfo?.isAdmin || currentUserInfo?.isFarmAdmin) && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                  paddingLeft: isMobile ? '2.5rem' : 0,
                }}
              >
                <Select
                  size='small'
                  value={reader.isAdmin ? 'admin' : 'reader'}
                  onChange={(e) => handleRoleChange(reader.email, e.target.value === 'admin')}
                  disabled={reader.email === currentUserInfo?.email}
                  sx={{
                    minWidth: 100,
                    '.MuiSelect-select': {
                      py: 0.5,
                      fontSize: '0.875rem',
                      color: 'rgba(0, 0, 0, 0.7)',
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      borderWidth: '1px',
                    },
                  }}
                >
                  <MenuItem value='reader'>
                    {intl.formatMessage({ id: 'role_reader', defaultMessage: 'Reader' })}
                  </MenuItem>
                  <MenuItem value='admin'>
                    {intl.formatMessage({ id: 'role_admin', defaultMessage: 'Admin' })}
                  </MenuItem>
                </Select>
                {reader.email !== currentUserInfo?.email ? (
                  <IconButton edge='end' onClick={() => handleRemoveReader(reader.email)}>
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    edge='end'
                    style={{
                      opacity: 0,
                      cursor: 'default',
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            )}
          </ListItem>
        );
      })}
      {deletedUsers.map((email) => (
        <ListItem key={email}>
          <ListItemText
            primary={email}
            primaryTypographyProps={{ style: { color: 'rgba(0, 0, 0, 0.6)', textDecoration: 'line-through' } }}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default FarmSettingsComponent;
