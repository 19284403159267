import type { Coordinates, DataLoggerConfiguration, ObservationSite, SoilDataSource } from '@soilsense/shared';
import { DEFAULT_BOT_DEPTH, DEFAULT_TOP_DEPTH } from '@soilsense/shared';
import type { SiteInfo } from 'dataHandlers/ObservationSiteStore';
import deepEqual from 'deep-equal';
import type { UserSettings } from 'interfaces/IUser';
import { extractDisabledRules } from '../../interfaces/Farm';
import type { IFarm } from '../../interfaces/IFarm';
import { DEFAULT_BOT_THRESHOLD, DEFAULT_TOP_THRESHOLD } from '../../interfaces/ISensor';
import type { LocationType } from '../DeviceWizards/LocationConfigurationInput';
import type { MidDepthValue } from './SensorDepths';
import type { InstantNotificationChannelType } from './inputComponents';

export interface IValidatedSensorSettings {
  [key: string]: IValidatedSensorSetting;
}
export interface ISensorSettings {
  [key: string]: ISensorSetting;
}

export interface IValidatedSensorSetting extends ISensorSetting {
  location?: Coordinates;
}

export interface ISensorSetting {
  // FIXME these three properties do not belong here at all since they are not
  // customizable; see also related refactoring task:
  // https://coda.io/d/_d6INXvxRRq0#Stories_tugSG/r73&modal=true
  id: string;
  deviceName: string | undefined;
  calibrated: boolean;

  // FIXME this is not an actual device setting - it's a flag for keeping track
  // of which devices are being moved
  moveDevice: boolean;

  name?: string;
  topDepth: number;
  midDepth: MidDepthValue;
  botDepth: number;
  pawStressThreshold: number | undefined;
  pawOverIrrigationThreshold: number | undefined;
  vwcStressThreshold: number | undefined;
  vwcOverIrrigationThreshold: number | undefined;
  subscribeToPawStress: boolean;
  subscribeToPawOverIrrigation: boolean;
  location?: Coordinates;
  type?: 'nbsensor' | 'sensor';
  cropType?: string;
  locationType?: LocationType;
}

export interface IUserSettings {
  PAWSwitchStatus: boolean;
  showSetupDialog: boolean;
  acceptedAiTerms: boolean;
  showAiTab: boolean;
}

export interface IFarmSettings {
  farmName: string;
  country: string | undefined;
  enableWhatsappNotif: boolean;
  phoneNumbers: string[];
  instantNotificationChannel: InstantNotificationChannelType;
  readers: string[];
  readerInfos: { id: string; email: string }[];
  frostEnabled?: boolean;
  frostThreshold?: number;
}

export const DEFAULT_FARM_SETTINGS: IFarmSettings = {
  farmName: '',
  country: undefined,
  enableWhatsappNotif: false,
  instantNotificationChannel: 'whatsapp',
  phoneNumbers: [] as string[],
  readers: [] as string[],
  readerInfos: [] as { id: string; email: string }[],
  frostEnabled: false,
  frostThreshold: undefined,
};

export const DEFAULT_USER_SETTINGS: IUserSettings = {
  PAWSwitchStatus: false,
  showSetupDialog: false,
  acceptedAiTerms: false,
  showAiTab: false,
};

export const userSettingsToFirebaseObj = (userSettings: Partial<IUserSettings>): Partial<UserSettings> => {
  return {
    pawSwitchAvailable: userSettings.PAWSwitchStatus,
    showSetupDialog: userSettings.showSetupDialog,
    acceptedAiTerms: userSettings.acceptedAiTerms,
    showAiTab: userSettings.showAiTab,
  };
};

export const userSettingsFromFirebaseObj = (settings: UserSettings | null | undefined): IUserSettings => {
  return {
    PAWSwitchStatus: settings?.pawSwitchAvailable ?? DEFAULT_USER_SETTINGS.PAWSwitchStatus,
    showSetupDialog: settings?.showSetupDialog ?? DEFAULT_USER_SETTINGS.showSetupDialog,
    acceptedAiTerms: settings?.acceptedAiTerms ?? DEFAULT_USER_SETTINGS.acceptedAiTerms,
    showAiTab: settings?.showAiTab ?? false,
  };
};

export const farmSettingsToFirebaseObj = (farmSettings: IFarmSettings): Partial<IFarm> => {
  return {
    name: farmSettings.farmName,
    country: farmSettings.country,
    settings: {
      enableInstantNotifications: farmSettings.enableWhatsappNotif,
      instantNotificationChannel: farmSettings.instantNotificationChannel,
      phoneNumbers: farmSettings.phoneNumbers,
      frost: {
        enabled: farmSettings.frostEnabled,
        threshold: farmSettings.frostThreshold,
      },
    },
    readers: farmSettings.readers,
    readerInfos: farmSettings.readerInfos,
  };
};

export const farmSettingsFromFirebaseObj = (farm: IFarm): IFarmSettings => {
  const channel = farm.settings?.instantNotificationChannel;
  return {
    farmName: farm.name ?? DEFAULT_FARM_SETTINGS.farmName,
    country: farm.country ?? DEFAULT_FARM_SETTINGS.country,
    enableWhatsappNotif: farm.settings?.enableInstantNotifications ?? DEFAULT_FARM_SETTINGS.enableWhatsappNotif,
    phoneNumbers: farm.settings?.phoneNumbers ?? DEFAULT_FARM_SETTINGS.phoneNumbers,
    instantNotificationChannel:
      channel == 'whatsapp'
        ? 'whatsapp'
        : channel == 'sms'
        ? 'sms'
        : DEFAULT_FARM_SETTINGS.instantNotificationChannel,
    readers: [...(farm.readers ?? DEFAULT_FARM_SETTINGS.readers)],
    readerInfos: [...(farm.readerInfos ?? DEFAULT_FARM_SETTINGS.readerInfos)],
    frostEnabled: farm.settings?.frost?.enabled ?? DEFAULT_FARM_SETTINGS.frostEnabled,
    frostThreshold: farm.settings?.frost?.threshold ?? DEFAULT_FARM_SETTINGS.frostThreshold,
  };
};

const STRESS_RULE_NAME = 'Plant stress';
const OVERIRRIGATION_RULE_NAME = 'Over-irrigation';

export const sensorSettingFromSiteInfo = (s: SiteInfo): IValidatedSensorSetting => {
  return {
    id: s.deviceIds.id,
    deviceName: s.deviceIds.deviceName,
    calibrated:
      s.configuration.cableBottom.calibration != undefined && s.configuration.cableTop.calibration != undefined,

    moveDevice: false,

    name: s.site.name ?? undefined,
    topDepth: s.configuration.cableTop.depth ?? DEFAULT_TOP_DEPTH,
    midDepth: s.configuration.cableMiddle?.depth ?? 'disabled',
    botDepth: s.configuration.cableBottom.depth ?? DEFAULT_BOT_DEPTH,
    pawStressThreshold: s.site.safeRanges.plantAvailableWater?.[0] ?? DEFAULT_BOT_THRESHOLD,
    pawOverIrrigationThreshold: s.site.safeRanges.plantAvailableWater?.[1] ?? DEFAULT_TOP_THRESHOLD,
    vwcStressThreshold: s.site.safeRanges.volumetricWaterContent?.[0],
    vwcOverIrrigationThreshold: s.site.safeRanges.volumetricWaterContent?.[1],
    subscribeToPawStress: s.site.disabledRules && s.site.disabledRules.includes(STRESS_RULE_NAME) ? false : true,
    subscribeToPawOverIrrigation:
      s.site.disabledRules && s.site.disabledRules.includes(OVERIRRIGATION_RULE_NAME) ? false : true,
    location: s.site.coordinates,
    cropType: s.site.cropType,
    locationType: s.site.locationType as LocationType,
  };
};

export const sensorSettingsFromSiteInfos = (siteInfos: readonly SiteInfo[]): ISensorSettings => {
  return siteInfos.reduce((prev, s) => {
    prev[s.deviceIds.id] = sensorSettingFromSiteInfo(s);
    return prev;
  }, {} as ISensorSettings);
};

export function determineObservationSiteUpdate(
  site: ObservationSite,
  lastSoilDataSource: SoilDataSource,
  settings: IValidatedSensorSetting,
  timestamp: number
): ObservationSite {
  const disabledRules = extractDisabledRules(settings);
  const name = settings.name ?? '';
  const { pawStressThreshold, pawOverIrrigationThreshold, vwcStressThreshold, vwcOverIrrigationThreshold } =
    settings;
  const coordinates = settings.location;
  const cropType = settings.cropType;
  const locationType = settings.locationType;

  const { deviceNumber, deviceName, configuration: lastConfiguration } = lastSoilDataSource;
  const configuration = updateDataLoggerConfiguration(lastSoilDataSource.configuration, settings);
  const soilDataSourceHistory = [...site.soilDataSourceHistory];
  if (deepEqual(lastConfiguration, configuration) === false) {
    soilDataSourceHistory.push({
      startTimestamp: timestamp,
      value: {
        deviceNumber,
        deviceName,
        configuration,
      },
    });
  }

  return {
    disabledRules,
    name,
    safeRanges: {
      plantAvailableWater:
        pawStressThreshold != undefined && pawOverIrrigationThreshold != undefined
          ? [pawStressThreshold, pawOverIrrigationThreshold]
          : undefined,
      volumetricWaterContent:
        vwcStressThreshold != undefined && vwcOverIrrigationThreshold != undefined
          ? [vwcStressThreshold, vwcOverIrrigationThreshold]
          : undefined,
      salinity: site.safeRanges.salinity,
    },
    coordinates: coordinates ?? site.coordinates,
    soilDataSourceHistory,
    cropType,
    locationType,
  };
}

function updateDataLoggerConfiguration(
  lastConfiguration: DataLoggerConfiguration,
  settings: IValidatedSensorSetting
): DataLoggerConfiguration {
  const { topDepth, midDepth, botDepth } = settings;
  const cableTop = { ...lastConfiguration.cableTop, depth: topDepth };
  const cableMiddle =
    midDepth == undefined || midDepth == 'disabled'
      ? undefined
      : { ...lastConfiguration.cableMiddle, depth: midDepth };
  const cableBottom = { ...lastConfiguration.cableBottom, depth: botDepth };
  const { topAndBottomCablesSwapped, placement } = lastConfiguration;
  return {
    cableTop,
    cableMiddle,
    cableBottom,
    topAndBottomCablesSwapped,
    placement,
  };
}
