import { ArrowDownward, Person } from '@mui/icons-material';
import { keyframes, styled } from '@mui/material/styles';
import { RobotHappy } from 'mdi-material-ui';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Markdown from 'react-markdown';

type MessagesProps = {
  children: React.ReactNode;
  onIsAtBottomChange?: (isAtBottom: boolean) => void;
};

const ScrollButton = styled('button')({
  position: 'sticky',
  bottom: '0px',
  left: '50%',
  transform: 'translateX(-50%)',
  minWidth: '40px',
  width: '40px',
  minHeight: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: '#fff',
  color: '#000',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0.9,
  transition: 'opacity 0.2s',
  '&:hover': {
    opacity: 1,
  },
  zIndex: 1,
  boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
  padding: 0,
  margin: '0 auto',
});

const MessagesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  overflowY: 'auto',
  flexGrow: 1,
  order: 2,
  height: '100%',
  maxHeight: '100%',
  position: 'relative',
  paddingBottom: '20px',
});

const MessageBase = styled('div')({
  margin: '8px 0',
  padding: '8px 16px',
  alignSelf: 'flex-start',
  borderRadius: '15px',
  maxWidth: '80%',
  overflowWrap: 'break-word',
  position: 'relative',
  '@media (max-width: 600px)': {
    fontSize: '0.9rem',
    padding: '6px 12px',
    maxWidth: '90%',
  },
});

const IconWrapper = styled('div')({
  position: 'absolute',
  width: '24px',
  height: '24px',
  backgroundColor: '#fff',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
});

export const UserMessageStyled = styled(MessageBase)({
  alignSelf: 'flex-end',
  color: '#fff',
  backgroundColor: '#000',
  '& .icon-wrapper': {
    top: '-12px',
    right: '-12px',
    '& svg': {
      color: '#000',
    },
  },
});

export const AssistantMessageStyled = styled(MessageBase)({
  backgroundColor: '#efefef',
  '& .icon-wrapper': {
    top: '-12px',
    left: '-12px',
  },
  '& img': {
    maxWidth: '100%',
    margin: '8px 0px',
    borderRadius: '8px',
  },
});

export const CodeMessageStyled = styled(MessageBase)({
  padding: '10px 16px',
  backgroundColor: '#e9e9e9',
  fontFamily: 'monospace',
  counterReset: 'line',
  '& > div': {
    marginTop: '4px',
  },
  '& span': {
    color: '#b8b8b8',
    marginRight: '8px',
  },
});

const dotAnimation = keyframes`
  0%, 20% { content: ''; }
  30% { content: '.'; }
  50% { content: '..'; }
  70% { content: '...'; }
  100% { content: ''; }
`;

const ThinkingMessage = styled(MessageBase)({
  backgroundColor: '#efefef',
  '&::after': {
    content: "'.'",
    animation: `${dotAnimation} 1.5s infinite`,
  },
});

export type MessageProps = {
  role: 'user' | 'assistant' | 'code';
  text: string;
  content?: Array<{
    type: string;
    text: {
      value: string;
      annotations: Array<{
        end_index: number;
        file_citation: { file_id: string };
        start_index: number;
        text: string;
        type: string;
      }>;
    };
  }>;
};

const UserMessage = ({ text }: { text: string }) => {
  if (text.includes('__initial_message__')) {
    return null;
  }
  return (
    <UserMessageStyled>
      <IconWrapper className='icon-wrapper'>
        <Person sx={{ fontSize: 16 }} />
      </IconWrapper>
      {text}
    </UserMessageStyled>
  );
};

const AssistantMessage = ({ text }: { text: string }) => {
  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    console.log('Link clicked:', e.currentTarget.href);
    const fileId = e.currentTarget.href.split('=')[1];
    console.log('File ID:', fileId);
    if (fileId) {
      (window as any).handleFileLink(fileId);
    }
  };

  return (
    <AssistantMessageStyled>
      <IconWrapper className='icon-wrapper'>
        <RobotHappy sx={{ fontSize: 16 }} />
      </IconWrapper>
      <Markdown
        components={{
          a: ({ node, ...props }) => (
            <a
              {...props}
              onClick={handleLinkClick}
              style={{ cursor: 'pointer', color: '#1976d2', textDecoration: 'none' }}
            />
          ),
        }}
      >
        {text}
      </Markdown>
    </AssistantMessageStyled>
  );
};

const CodeMessage = ({ text }: { text: string }) => {
  return (
    <CodeMessageStyled>
      {text.split('\n').map((line, index) => (
        <div key={index}>
          <span>{`${index + 1}. `}</span>
          {line}
        </div>
      ))}
    </CodeMessageStyled>
  );
};

const ThinkingIndicator = () => {
  const intl = useIntl();
  const thinkingMessage = intl.formatMessage({
    id: 'chat_thinking',
    defaultMessage: 'Thinking...',
  });
  return <ThinkingMessage>{thinkingMessage.replace('...', '')}</ThinkingMessage>;
};

export const Message = ({ role, text }: MessageProps): React.ReactElement => {
  const intl = useIntl();
  const thinkingMessage = intl.formatMessage({
    id: 'chat_thinking',
    defaultMessage: 'Thinking...',
  });
  switch (role) {
    case 'user':
      return <UserMessage text={text} />;
    case 'assistant':
      return text === thinkingMessage ? <ThinkingIndicator /> : <AssistantMessage text={text} />;
    case 'code':
      return <CodeMessage text={text} />;
    default:
      return <div>{text}</div>;
  }
};

export const Messages = React.forwardRef<HTMLDivElement, MessagesProps>(
  ({ children, onIsAtBottomChange }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [isScrollable, setIsScrollable] = useState(false);

    const scrollToBottom = useCallback(() => {
      if (containerRef.current) {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, []);

    useEffect(() => {
      const container = containerRef.current;
      const checkScrollable = () => {
        if (container) {
          const isContentScrollable = container.scrollHeight > container.clientHeight;
          setIsScrollable(isContentScrollable);

          const { scrollHeight, scrollTop, clientHeight } = container;
          const bottomThreshold = 100;
          const isAtBottom = scrollHeight - (scrollTop + clientHeight) <= bottomThreshold;
          setIsAtBottom(isAtBottom);
          onIsAtBottomChange?.(isAtBottom);
        }
      };

      // Check initially and on resize
      checkScrollable();
      const resizeObserver = new ResizeObserver(checkScrollable);
      if (container) {
        resizeObserver.observe(container);
      }

      // Check on scroll
      container?.addEventListener('scroll', checkScrollable);

      return () => {
        container?.removeEventListener('scroll', checkScrollable);
        resizeObserver.disconnect();
      };
    }, [onIsAtBottomChange]);

    return (
      <MessagesContainer
        ref={(node) => {
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            (ref as React.MutableRefObject<HTMLDivElement | null>).current = node;
          }
          if (containerRef) {
            (containerRef as React.MutableRefObject<HTMLDivElement | null>).current = node;
          }
        }}
      >
        {children}
        {isScrollable && !isAtBottom && (
          <ScrollButton onClick={scrollToBottom} aria-label='Scroll to bottom'>
            <ArrowDownward />
          </ScrollButton>
        )}
      </MessagesContainer>
    );
  }
);
