import { BASE_URL } from './consts';

const latitudeLongitudeFromLocation = async (location: string) => {
  const apiUrl = `https://nominatim.openstreetmap.org/search?q=${location}&format=json`;
  const response = await fetch(apiUrl);
  const data = await response.json();
  return data[0];
};

const locationFromLatLon = async (lat: number, lon: number) => {
  const apiUrl = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`;
  const response = await fetch(apiUrl);
  const data = await response.json();
  return data.display_name;
};

export type Weather = {
  location: string;
  temperature: number;
  unit: string;
  conditions: string;
};

const getCurrentWeather = async (location: string): Promise<Weather> => {
  // chose a random temperature and condition
  // const randomTemperature = Math.floor(Math.random() * (80 - 50 + 1)) + 50;
  // const randomConditionIndex = Math.floor(Math.random() * 5);
  // const conditions = ['Cloudy', 'Sunny', 'Rainy', 'Snowy', 'Windy'];

  const weatherCodeToCondition = (code: number) => {
    const conditions = ['Cloudy', 'Sunny', 'Rainy', 'Snowy', 'Windy'];
    return conditions[code];
  };

  const { lat, lon } = await latitudeLongitudeFromLocation(location);
  console.log({ lat, lon });
  const apiUrl = `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lon}&current_weather=true`;
  const response = await fetch(apiUrl);
  const data = await response.json();

  console.log('current', data);

  return {
    location: location,
    temperature: data.current_weather.temperature,
    unit: data.current_weather_units.temperature,
    conditions: weatherCodeToCondition(data.current_weather.weathercode as number),
  };
};

export type WeatherForecast = {
  timestamp: Date;
  temperature: number;
  precipitation: number;
  windSpeed: number;
  windDirection: number;
  sunrise: Date;
  sunset: Date;
};

const getWeatherForecast = async (location: string): Promise<WeatherForecast[]> => {
  const { lat, lon } = await latitudeLongitudeFromLocation(location);
  const apiUrl = `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lon}&daily=temperature_2m_max,temperature_2m_min,precipitation_probability_max,precipitation_probability_min,precipitation_sum,windspeed_10m_max,winddirection_10m_dominant,sunrise,sunset`;
  const response = await fetch(apiUrl);
  const data = await response.json();
  console.log('forecast', data);
  return data.daily;
};

const getWeatherForecastLatLon = async (lat: number, lon: number): Promise<WeatherForecast[]> => {
  const apiUrl = `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lon}&daily=temperature_2m_max,temperature_2m_min,precipitation_probability_max,precipitation_probability_min,precipitation_sum,windspeed_10m_max,winddirection_10m_dominant,sunrise,sunset`;
  const response = await fetch(apiUrl);
  const data = await response.json();
  console.log('forecast', data);
  return data.daily;
};

const getWeatherForecastFromOpenWeatherMap = async (siteId: string): Promise<WeatherForecast[]> => {
  const apiUrl = `${BASE_URL}/observation-sites/${siteId}/weather_forcast`;
  const response = await fetch(apiUrl);
  const data = await response.json();
  console.log('forecast', data);
  return data.daily;
};

// getCurrentWeather('Warsaw').then((data) => console.log(data));
// getWeatherForecast('Warsaw').then((data) => console.log(data));

export { getCurrentWeather as getWeather, getWeatherForecast, getWeatherForecastLatLon, locationFromLatLon };
