// import EventNoteIcon from '@mui/icons-material/EventNote';
// import TimelineIcon from '@mui/icons-material/Timeline';
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import DataThresholdingOutlinedIcon from '@mui/icons-material/DataThresholdingOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import React from 'react';

export enum TabLabels {
  Overview,
  Charts,
  AI,
}
export const tabLabels = ['Overview', 'Charts', 'Assistants'];
export const tabIcons = [
  <MapOutlinedIcon key={0} />,
  <DataThresholdingOutlinedIcon key={1} />,
  <AssistantOutlinedIcon key={2} />,
];
