import type { Day, Locale } from '@hassanmojab/react-modern-calendar-datepicker';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { Close } from '@mui/icons-material';
import type { InputProps, TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC } from 'react';
import React from 'react';
import { FaRegCalendarAlt } from 'react-icons/fa';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';
import { useFarmStore } from '../dataHandlers/RootStore';
import { i18RangeDatePickerLocale } from '../i18n/months';
import CardBottomButton from './CardBottomButton';

const useStyles = makeStyles(() => ({
  Calendar: {
    position: 'fixed',
    top: 'calc(50vh - 19em)',
    left: 'calc(50vw - 16em)',
    '& > Calendar__footer': {
      textAlign: 'center',
    },
  },
  DatepickerContainer: {
    fontSize: (props: IStyleProps) => (props.size === 'small' ? '10px' : 'inherit'),
    '&:hover': {
      backgroundColor: (props: IStyleProps) => (props.isPending ? 'initial' : '#fefefe'),
      color: '#4a5b49',
      transition: 'all 0.3s ease-in-out',
      '& $CalendarIcon': {
        transform: 'scale(1.05) rotate(-3deg)',
      },
    },
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    cursor: (props: IStyleProps) => (props.isPending ? 'initial' : 'pointer'),
    alignItems: 'center',
    padding: '1em 1.2em',
    justifyContent: 'center',
    transition: 'all 0.3s ease-in-out',
  },
  CalendarIcon: {
    fontSize: '6.5em',
    marginRight: '0.3em',
    color: COLOR,
    transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  },
  TextFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    paddingTop: '0.1em',
    paddingRight: '0.1em',
  },
  ShadowBehindCalendar: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 2,
  },
}));

interface IStyleProps {
  isPending?: boolean;
  size?: 'default' | 'small';
}

const COLOR = '#535353';

export interface IDateRangeObj {
  startDate: Moment | undefined;
  endDate: Moment | undefined;
}

export type IValidatedDateRangeObj = Readonly<{
  startDate: Moment;
  endDate: Moment;
}>;

interface IProps extends IStyleProps {
  format?: string;
}

interface IState {
  datepickerOpened: boolean;
  datepickerDates: IDateRangeObj;
}

interface CloseDatepicker {
  type: 'CLOSE_DATEPICKER';
  dateRange: IValidatedDateRangeObj;
}

interface ToggleDatepickerOpenedAction {
  type: 'TOGGLE_DATEPICKER_OPENED';
  dateRange: IValidatedDateRangeObj;
}

interface SetDates {
  type: 'SET_DATES';
  dates: IDateRangeObj;
}

const datesReducer = (state: IState, action: SetDates | ToggleDatepickerOpenedAction | CloseDatepicker) => {
  switch (action.type) {
    case 'CLOSE_DATEPICKER':
      if (state.datepickerOpened) {
        return {
          ...state,
          datepickerDates: action.dateRange,
          datepickerOpened: false,
        };
      }

      return state;
    case 'TOGGLE_DATEPICKER_OPENED':
      return {
        ...state,
        datepickerDates: action.dateRange,
        datepickerOpened: !state.datepickerOpened,
      };
    case 'SET_DATES':
      return {
        ...state,
        datepickerDates: action.dates,
      };
    default:
      throw new Error();
  }
};

const RangeDatePicker: FC<IProps> = observer(({ isPending = false, size = 'default' }) => {
  const farmStore = useFarmStore();
  const classes = useStyles({ size, isPending });
  const theme = useTheme();
  const intl = useIntl();
  const [isPressed, setIsPressed] = React.useState(false);

  const [{ datepickerOpened, datepickerDates }, dispatch] = React.useReducer(datesReducer, {
    datepickerOpened: false,
    datepickerDates: farmStore.selectedDates,
  });

  const toggleDatepickerOpened = () =>
    dispatch({ type: 'TOGGLE_DATEPICKER_OPENED', dateRange: farmStore.selectedDates });
  const setDatepickerDates = (dates: IDateRangeObj) => dispatch({ dates, type: 'SET_DATES' });
  const selectedDayRange = dayRangeFromDateRangeObj(datepickerDates);

  React.useEffect(() => {
    setDatepickerDates(farmStore.selectedDates);
  }, [farmStore.selectedDates]);

  return (
    <>
      <div
        className={classes.DatepickerContainer}
        onClick={toggleDatepickerOpened}
        onMouseDown={() => setIsPressed(true)}
        onMouseUp={() => setIsPressed(false)}
        onMouseLeave={() => setIsPressed(false)}
        data-testid='datepicker-container'
      >
        <FaRegCalendarAlt
          className={classes.CalendarIcon}
          style={
            isPressed
              ? { transform: 'scale(0.7)', transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)' }
              : undefined
          }
        />
        <div className={classes.TextFieldsContainer}>
          <CustomTextField
            id='selected-date-from'
            // label='From'
            label={intl.formatMessage({ id: 'from' })}
            value={dayMonthYearToString(selectedDayRange.from, intl)}
            clickable={!isPending}
          />
          <CustomTextField
            id='selected-date-to'
            // label='To'
            label={intl.formatMessage({ id: 'to' })}
            value={dayMonthYearToString(selectedDayRange.to, intl)}
            clickable={!isPending}
          />
        </div>
      </div>

      {datepickerOpened && <div onClick={toggleDatepickerOpened} className={classes.ShadowBehindCalendar} />}

      {datepickerOpened && (
        // needs to change to react-datepicker to use localisation
        // https://stackoverflow.com/questions/54399084/change-locale-in-react-datepicker
        <Calendar
          value={selectedDayRange}
          onChange={(newDayRange: { from: Day; to: Day }) => {
            const newDates = {
              startDate: momentFromDateMonthYear(newDayRange.from),
              endDate: momentFromDateMonthYear(newDayRange.to),
            } as IDateRangeObj;

            const validDates = validateDates(newDates);
            if (validDates != undefined) {
              farmStore.setSelectedDates(validDates.startDate, validDates.endDate);
            }
            setDatepickerDates(newDates);
          }}
          shouldHighlightWeekends
          minimumDate={minimumDate}
          maximumDate={dayMonthYearFromMoment(moment())}
          colorPrimaryLight={theme.palette.success.light}
          colorPrimary={theme.palette.primary.main}
          calendarClassName={classes.Calendar}
          renderFooter={() => (
            <CloseButton
              onClick={() => {
                dispatch({ type: 'CLOSE_DATEPICKER', dateRange: farmStore.selectedDates });
              }}
              text={intl.formatMessage({ id: 'close' })}
            />
          )}
          locale={i18RangeDatePickerLocale[intl.locale] as Locale}
        />
      )}
    </>
  );
});

const CloseButton = ({ onClick, text }: { onClick: () => void; text: string }) => (
  <CardBottomButton autoFocus color='inherit' onClick={onClick} icon={<Close />} text={text} />
);

const dayRangeFromDateRangeObj = (dateRange: IDateRangeObj) => ({
  from: dayMonthYearFromMoment(dateRange.startDate),
  to: dayMonthYearFromMoment(dateRange.endDate),
});

const datesAreValid = (newDates: IDateRangeObj): boolean =>
  newDates.startDate != null &&
  newDates.endDate != null &&
  newDates.startDate.isValid() &&
  newDates.endDate.isValid();

const validateDates = (newDates: IDateRangeObj): IValidatedDateRangeObj | undefined => {
  if (datesAreValid(newDates)) {
    return newDates as IValidatedDateRangeObj;
  }

  return undefined;
};

const dayMonthYearFromMoment = (date: Moment | undefined): Day | undefined => {
  if (date == null) {
    return undefined;
  }
  const month = Number(date.format('M'));
  const day = Number(date.format('D'));
  const year = Number(date.format('YYYY'));
  return {
    day,
    month,
    year,
  };
};

const momentFromDateMonthYear = (dayDate: Day | undefined, format = 'YYYY-MM-DD'): Moment | undefined => {
  if (dayDate == null) {
    return undefined;
  }
  const { day, month, year } = dayDate;
  return moment(`${year}-${month}-${day}`, format);
};

const minimumDate = {
  year: 2016,
  month: 1,
  day: 1,
};

const dayMonthYearToString = (dayMonthYear: Day | undefined, intl: IntlShape): string | JSX.Element => {
  const m = momentFromDateMonthYear(dayMonthYear);
  if (!m) {
    return '...';
  }
  return intl.formatDate(m.toDate(), { year: 'numeric', month: 'long', day: 'numeric' });
};

const useStylesTextField = makeStyles(() => ({
  input: {
    fontSize: '22px',
    marginTop: '-8px',
    cursor: (props: { clickable: boolean }) => (props.clickable ? 'pointer' : 'default'),
    caretColor: 'transparent',
    padding: '3px 0 3px',
    color: COLOR,
  },
  focused: {},
}));

const CustomTextField = (props: TextFieldProps & { clickable: boolean }) => {
  const { clickable, ...textFieldProps } = props;
  const classes = useStylesTextField({ clickable });

  return (
    <TextField
      InputProps={{ classes, disableUnderline: true, readOnly: true } as Partial<InputProps>}
      variant='standard'
      {...textFieldProps}
    />
  );
};

export default RangeDatePicker;
