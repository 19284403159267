import Signup from 'components/Signup';
import { useCurrentUser, useFarmStore, useFirestore, useUserStore } from 'dataHandlers/RootStore';
import { doc } from 'firebase/firestore';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { Redirect, useHistory, useLocation } from 'react-router';
import getErrorMessageIntl from 'utils/getErrorMessageIntl';
import { SoilsenseBackgroundOverlay } from '../components/BackgroundOverlayContainer';
import type { ISignupForm } from '../components/Signup';
import { SeverityLevel, showErrorSnackBar, showSnackBar } from '../components/SnackBar';

const SignupPage = observer((): JSX.Element => {
  const userStore = useUserStore();
  const farmStore = useFarmStore();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const prefilledEmail = searchParams.get('e') || '';
  const decodedEmail = prefilledEmail
    ? Buffer.from(Buffer.from(prefilledEmail, 'base64').toString('utf8'), 'base64').toString('utf8')
    : '';

  if (prefilledEmail) {
    searchParams.delete('e');
    history.replace({ search: searchParams.toString() });
  }

  const firestore = useFirestore();
  const userDoc = doc(firestore, `users/${userStore.currentUser?.uid}`);
  const [userData, loadingUser, errorUser] = useDocumentData(userDoc);

  useEffect(() => {
    if (userData && userData.farmPermissions) {
      history.push('/', { from: 'signup' });
      setLoading(false);
    }
  }, [userData, history]);

  const handleSignUp = useCallback(
    async (event: React.FormEvent<HTMLFormElement & ISignupForm>) => {
      event.preventDefault();
      const {
        email: { value: email },
        password: { value: password },
        confirmPassword: { value: confirmPassword },
        newsletter: { checked: newsletterConsent },
        termsAndConditions: { checked: termsAccepted },
      } = (event.target as HTMLFormElement & ISignupForm).elements;

      if (!termsAccepted) {
        showErrorSnackBar(intl.formatMessage({ id: 'terms_and_conditions_required' }));
        setLoading(false);
        return;
      }

      if (password !== confirmPassword) {
        showErrorSnackBar(intl.formatMessage({ id: 'passwords_do_not_match' }));
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        await userStore.createUserWithEmailAndPassword(email.trim(), password, async (authUser) => {
          if (!authUser || !authUser.user) {
            throw new Error(intl.formatMessage({ id: 'signup_failed_creating_user' }, { email }));
          }

          await userStore.createUser(authUser.user.uid, email, newsletterConsent);
          showSnackBar(SeverityLevel.success, intl.formatMessage({ id: 'signup_success' }, { email }));
        });

        // Wait for auth state and farm permissions to be updated in the backend
        await new Promise((resolve) => setTimeout(resolve, 2000));
      } catch (err) {
        showErrorSnackBar(
          intl.formatMessage(
            { id: 'signup_error' },
            {
              error: getErrorMessageIntl(err, intl),
            }
          )
        );
      } finally {
        setLoading(false);
      }
    },
    [userStore, intl]
  );

  const currentUser = useCurrentUser();
  if (currentUser) {
    return <Redirect to={{ pathname: '/', state: { from: 'signup' } }} />;
  }

  return (
    <SoilsenseBackgroundOverlay>
      <Signup onSubmit={handleSignUp} loading={loading} prefilledEmail={decodedEmail} />
    </SoilsenseBackgroundOverlay>
  );
});

export default SignupPage;
