import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ArrowBack, Close } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { Refresh } from 'mdi-material-ui';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import agrigptimage from '../../assets/agrigpt.jpg';
import irrigptimage from '../../assets/irrigpt.jpg';
import { useCurrentUser, useObservationSiteStore } from '../../dataHandlers/RootStore';
import useIsMobile from '../../utils/useIsMobile';
import type { AIState, AIStateActions } from './hooks/useAIState';
import SiteSelector from './SiteSelector';

interface AIControlPanelProps {
  aiState: AIState;
  aiActions: AIStateActions;
  hasSiteData?: boolean;
}

const ChatHistoryItem = styled(ListItemButton)<{ active?: boolean }>(({ active }) => ({
  position: 'relative',
  '&::before': active
    ? {
        content: '""',
        position: 'absolute',
        left: '8px',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '8px',
        height: '8px',
        backgroundColor: '#4caf50',
        borderRadius: '50%',
        animation: `${pulseKeyframes} 2s infinite`,
      }
    : {},
  paddingLeft: active ? '24px' : '16px',
}));

const pulseKeyframes = keyframes`
  0% {
    transform: translateY(-50%) scale(0.95);
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7);
  }
  
  70% {
    transform: translateY(-50%) scale(1);
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }
  
  100% {
    transform: translateY(-50%) scale(0.95);
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
`;

const AIControlPanel: React.FC<AIControlPanelProps> = ({ aiState, aiActions, hasSiteData = false }) => {
  const [newChatDialogOpen, setNewChatDialogOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [chatType, setChatType] = useState<'general' | 'site-specific' | null>(null);
  const [period, setPeriod] = useState<'7days' | '30days' | null>(null);
  const [initDone, setInitDone] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [historyLoadError, setHistoryLoadError] = useState<string | null>(null);
  const currentUser = useCurrentUser()!;
  const acceptedAiTerms = currentUser.acceptedAiTerms;
  const observationSiteStore = useObservationSiteStore();
  const [cropLocationDialogOpen, setCropLocationDialogOpen] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    document.addEventListener('crop-location-type-dialog-open', () => {
      console.log('listener: crop-location-type-dialog-open');
      setCropLocationDialogOpen(true);
    });

    document.addEventListener('crop-location-type-dialog-close', () => {
      console.log('listener: crop-location-type-dialog-close');
      setCropLocationDialogOpen(false);
    });

    return () => {
      document.removeEventListener('crop-location-type-dialog-open', () => {});
      document.removeEventListener('crop-location-type-dialog-close', () => {});
    };
  }, []);

  useEffect(() => {
    if (period) {
      aiActions.setPeriod(period);
    }
  }, [aiActions, period]);

  useEffect(() => {
    if (
      acceptedAiTerms &&
      !aiState.initialLoading &&
      !aiState.allThreadsLoading &&
      !aiState.existsThreadInLast5Minutes &&
      !aiState.threadId &&
      !initDone &&
      !error
    ) {
      setNewChatDialogOpen(true);
      setInitDone(true);
    }
  }, [
    acceptedAiTerms,
    aiState.existsThreadInLast5Minutes,
    aiState.initialLoading,
    aiState.allThreadsLoading,
    aiState.threadId,
    initDone,
    error,
    cropLocationDialogOpen,
  ]);

  useEffect(() => {
    if (step === 1) {
      setChatType(null);
      setPeriod(null);
    }
  }, [step]);

  const handleNewChat = () => {
    setError(null);
    setNewChatDialogOpen(true);
    setStep(1);
    setChatType(null);
    setPeriod(null);
  };

  const handleChatTypeSelect = (type: 'general' | 'site-specific') => {
    setChatType(type);
    if (type === 'general') {
      startChat(type);
    } else {
      setStep(2);
    }
  };

  const handlePeriodSelect = (selectedPeriod: '7days' | '30days') => {
    setPeriod(selectedPeriod);
    setStep(3);
  };

  const startChat = async (chatType: 'general' | 'site-specific') => {
    try {
      if (chatType === 'general') {
        aiActions.setSelectedSiteId('');
        aiActions.setSelectedHistoricThreadId(null);
        aiActions.setThreadId(null);
        aiActions.setChatKey((prev: number) => prev + 1);
        setNewChatDialogOpen(false);
        aiActions.setGeneralChat(true);
      } else {
        aiActions.setThreadId(null);
        aiActions.setSelectedHistoricThreadId(null);
        aiActions.setChatKey((prev: number) => prev + 1);
        setNewChatDialogOpen(false);
        aiActions.setGeneralChat(false);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to start chat');
      aiActions.setThreadId(null);
      aiActions.setGeneralChat(null);
      setNewChatDialogOpen(false);
    }
  };

  const handleBack = () => {
    if (step === 2) {
      setStep(1);
      setChatType(null);
      setPeriod(null);
    } else if (step === 3) {
      setStep(2);
    }
  };

  const handleDialogClose = () => {
    console.log('handleDialogClose');
    setNewChatDialogOpen(false);

    // Only set thread states if we don't already have an active thread
    if (!aiState.threadId && !aiState.selectedHistoricThreadId) {
      // Check for active thread in last 10 minutes
      const lastTenMinutes = new Date(Date.now() - 10 * 60 * 1000);
      const activeThread = aiState.threads.find((thread) => new Date(thread.createdAt) > lastTenMinutes);

      if (activeThread) {
        console.log('setting active threadId', activeThread.threadId);
        aiActions.setThreadId(activeThread.threadId);
        aiActions.setSelectedHistoricThreadId(null);
      } else if (aiState.threads && aiState.threads.length > 0) {
        // Set newest thread as historic thread
        const newestThread = aiState.threads[0];
        console.log('setting historic threadId', newestThread.threadId);
        aiActions.setThreadId(null);
        aiActions.setSelectedHistoricThreadId(newestThread.threadId);
      }
    }
  };

  const handleRetryLoadHistory = () => {
    setHistoryLoadError(null);
    aiActions.fetchAllThreads().catch((err) => {
      setHistoryLoadError(err instanceof Error ? err.message : 'Failed to load chat history');
    });
  };

  if (error) {
    return (
      <div
        style={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <Typography color='error'>{error}</Typography>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            setError(null);
            setInitDone(false);
          }}
        >
          <FormattedMessage id='try_again' defaultMessage='Try Again' />
        </Button>
      </div>
    );
  }

  return (
    <div
      style={{ padding: '16px', height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
    >
      <div style={{ display: 'flex', gap: '8px', marginBottom: '16px' }}>
        <Button variant='contained' color='success' onClick={handleNewChat} fullWidth disabled={!acceptedAiTerms}>
          <FormattedMessage id='chat_start_chat' />
        </Button>
      </div>

      {acceptedAiTerms && !cropLocationDialogOpen ? (
        <>
          <Dialog open={newChatDialogOpen} onClose={handleDialogClose}>
            <DialogTitle>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
                  {step > 1 && (
                    <Button onClick={handleBack} sx={{ mr: 2 }}>
                      <ArrowBack />
                    </Button>
                  )}
                  <span style={{ fontWeight: 'bold', marginRight: '8px' }}>
                    <FormattedMessage id='assistants' />
                    {!isMobile ? ':' : ''}
                  </span>
                  {isMobile && (
                    <div style={{ width: '100%', height: '1px', backgroundColor: 'black', margin: '0 8px' }} />
                  )}
                  <span style={{ fontSize: isMobile ? '1.1rem' : '1.3rem' }}>
                    <FormattedMessage id='start_new_chat' />
                  </span>
                </div>
                <Button onClick={handleDialogClose}>
                  <Close />
                </Button>
              </div>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: '16px' }}>
              {
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: '16px',
                    justifyContent: 'center',
                  }}
                >
                  {(!chatType || chatType === 'general') && (
                    <Card
                      sx={{
                        cursor: 'pointer',
                        width: { xs: '100%', sm: hasSiteData ? '45%' : '100%' },
                        minWidth: { xs: '280px', sm: 'auto' },
                        '&:hover': { boxShadow: 3 },
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      onClick={() => handleChatTypeSelect('general')}
                    >
                      <CardMedia component='img' height='140' image={agrigptimage} alt='AgriGPT' />
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography gutterBottom variant='h5' component='div'>
                          <FormattedMessage id='chat_title_agri_short' />
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                          <FormattedMessage id='chat_description_agri' />
                        </Typography>
                      </CardContent>
                      <Button
                        variant='contained'
                        color='success'
                        sx={{ margin: '16px' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleChatTypeSelect('general');
                        }}
                      >
                        <FormattedMessage id='chat_start_chat' />
                      </Button>
                    </Card>
                  )}
                  {hasSiteData && (
                    <Card
                      sx={{
                        cursor: 'pointer',
                        width: { xs: '100%', sm: '45%' },
                        minWidth: { xs: '280px', sm: 'auto' },
                        '&:hover': { boxShadow: 3 },
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      onClick={() => handleChatTypeSelect('site-specific')}
                    >
                      <CardMedia component='img' height='140' image={irrigptimage} alt='IrriGPT' />
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography gutterBottom variant='h5' component='div'>
                          <FormattedMessage id='chat_title_field_short' />
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                          <FormattedMessage id='chat_description_field' />
                        </Typography>
                      </CardContent>
                      {chatType !== 'site-specific' && (
                        <Button
                          variant='contained'
                          color='success'
                          sx={{ margin: '16px' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleChatTypeSelect('site-specific');
                          }}
                        >
                          <FormattedMessage id='chat_select_time_period_button' />
                        </Button>
                      )}
                    </Card>
                  )}
                </div>
              }

              {step === 2 && chatType === 'site-specific' && (
                <>
                  <Typography variant='body1' sx={{ mb: 0, mt: 4, textAlign: 'center' }}>
                    <FormattedMessage id='chat_select_time_period_description' />
                  </Typography>
                  <div style={{ display: 'flex', gap: '16px', padding: '16px' }}>
                    <Button
                      variant='contained'
                      color='success'
                      onClick={() => handlePeriodSelect('7days')}
                      fullWidth
                    >
                      <FormattedMessage id='chat_7_days' />
                    </Button>
                    <Button
                      variant='contained'
                      color='success'
                      onClick={() => handlePeriodSelect('30days')}
                      fullWidth
                    >
                      <FormattedMessage id='chat_30_days' />
                    </Button>
                  </div>
                </>
              )}

              {step === 3 && (
                <>
                  <Typography variant='body1' sx={{ mb: 0, mt: 4, textAlign: 'center' }}>
                    <FormattedMessage id='chat_select_site' />
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      gap: '16px',
                      padding: '16px',
                      paddingTop: '0px',
                      flexDirection: 'column',
                    }}
                  >
                    <SiteSelector
                      selectedSiteId={aiState.selectedSiteId}
                      setSelectedSiteId={(id: string) => {
                        aiActions.setSelectedSiteId(id);
                        startChat('site-specific');
                      }}
                    />
                  </div>
                </>
              )}
            </DialogContent>
          </Dialog>

          {aiState?.threads?.length > 0 && (
            <List sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', overflowY: 'scroll' }}>
              <ListItem
                secondaryAction={
                  <Button onClick={handleRetryLoadHistory} size='small'>
                    <Refresh />
                  </Button>
                }
              >
                <ListItemText primary={<FormattedMessage id='chat_history' />} sx={{ textAlign: 'center' }} />
              </ListItem>
              <Divider />

              {historyLoadError ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '16px',
                    gap: '8px',
                  }}
                >
                  <Typography color='error'>{historyLoadError}</Typography>
                  <Button variant='contained' color='primary' onClick={handleRetryLoadHistory}>
                    <FormattedMessage id='try_again' defaultMessage='Try Again' />
                  </Button>
                </div>
              ) : aiState.initialLoading || aiState.allThreadsLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
                  <CircularProgress />
                </div>
              ) : (
                aiState?.threads?.map((thread) => {
                  const siteName = thread.siteId ? observationSiteStore.getSiteName(thread.siteId) : undefined;
                  const isActive = new Date(thread.createdAt) > new Date(Date.now() - 10 * 60 * 1000);
                  return (
                    <ChatHistoryItem
                      key={thread.threadId}
                      active={isActive}
                      selected={
                        aiState.selectedHistoricThreadId === thread.threadId ||
                        aiState.threadId === thread.threadId
                      }
                      onClick={() => {
                        if (
                          aiState.selectedHistoricThreadId === thread.threadId ||
                          aiState.threadId === thread.threadId
                        ) {
                          return;
                        }
                        aiActions.setSelectedHistoricThreadId(thread.threadId);
                        aiActions.setThreadId(null);
                      }}
                    >
                      <ListItemText
                        primary={
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>
                              {thread.chatType === 'irrigation' && siteName
                                ? `${siteName} ${new Date(thread.createdAt).toLocaleDateString()}`
                                : thread.summary || `Chat ${new Date(thread.createdAt).toLocaleDateString()}`}
                            </span>
                            <span
                              style={{
                                fontSize: '0.8em',
                                padding: '2px 8px',
                                borderRadius: '12px',
                                backgroundColor:
                                  thread.chatType === 'general'
                                    ? '#e8f5e9'
                                    : thread.chatType === 'irrigation'
                                    ? '#e3f2fd'
                                    : '#dadada',
                                color:
                                  thread.chatType === 'general'
                                    ? '#2e7d32'
                                    : thread.chatType === 'irrigation'
                                    ? '#1976d2'
                                    : '#fff',
                                textTransform: 'capitalize',
                              }}
                            >
                              <FormattedMessage
                                id={
                                  thread.chatType === 'general'
                                    ? 'chat_agri'
                                    : thread.chatType === 'irrigation'
                                    ? 'chat_field'
                                    : 'unknown'
                                }
                              />
                            </span>
                          </div>
                        }
                        secondary={
                          thread.summary
                            ? `${new Date(thread.createdAt).toLocaleDateString()} ${new Date(
                                thread.createdAt
                              ).toLocaleTimeString()}`
                            : new Date(thread.createdAt).toLocaleTimeString()
                        }
                      />
                    </ChatHistoryItem>
                  );
                })
              )}
            </List>
          )}
          {/* 
          {aiState.selectedHistoricThreadId && (
            <div style={{ flex: 1, overflowY: 'auto', marginTop: '16px' }}>
              {aiState.loadingHistoricThread ? (
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress />
                </div>
              ) : (
                <Messages>
                  {aiState.historicThreadMessages &&
                    aiState.historicThreadMessages.map((msg, index) => (
                      <Message
                        key={`${aiState.selectedHistoricThreadId}-${msg.role}-${index}`}
                        role={msg.role as 'user' | 'assistant' | 'code'}
                        text={msg.content.map((content) => content.text.value).join('')}
                      />
                    ))}
                </Messages>
              )}
            </div>
          )} */}
        </>
      ) : null}
    </div>
  );
};

export default AIControlPanel;
