import type { ObservationSite } from '@soilsense/shared';
import type { IValidatedSensorSetting } from '../components/Settings/settingsState';

const STRESS_RULE_NAME = 'Plant stress';
const OVERIRRIGATION_RULE_NAME = 'Over-irrigation';

export function extractDisabledRules(settings: IValidatedSensorSetting): string[] {
  const disabledRules = [];
  if (!settings.subscribeToPawStress) disabledRules.push(STRESS_RULE_NAME);
  if (!settings.subscribeToPawOverIrrigation) disabledRules.push(OVERIRRIGATION_RULE_NAME);
  return disabledRules;
}

export function archivedObservationSite(site: ObservationSite, timestamp?: number): ObservationSite {
  let startTimestamp = timestamp;
  if (!startTimestamp) {
    startTimestamp = Date.now();
  }
  const soilDataSourceHistory = [...site.soilDataSourceHistory];
  soilDataSourceHistory.push({
    startTimestamp,
    value: undefined,
  });
  return { ...site, soilDataSourceHistory };
}
