import OpenWithIcon from '@mui/icons-material/OpenWith';
import type { Coordinates } from '@soilsense/shared';
import { DEFAULT_BOT_DEPTH, DEFAULT_TOP_DEPTH } from '@soilsense/shared';
import { useFirestore, useObservationSiteStore } from 'dataHandlers/RootStore';
import { doc } from 'firebase/firestore';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import Accordion from '../Accordion';
import type { MidDepthValue } from '../Settings/SensorDepths';
import { useStyles } from '../Settings/styles';
import CoordinatesInput from './CoordinatesInput';
import type { IStep } from './CustomStepper';
import CustomStepper from './CustomStepper';
import DeviceSelector from './DeviceSelector';
import LocationConfigurationInput, { type LocationType } from './LocationConfigurationInput';
import { goToInstallationStep, resetDataLoggerStep, turnOnNbLoggerStep } from './commonSteps';
import useGeolocator from './useGeolocator';

type DeviceParameters = Readonly<{
  deviceId: string;
  coordinates: Partial<Coordinates>;
  name: string;
  topDepth: number | undefined;
  midDepth: MidDepthValue;
  botDepth: number | undefined;
  cropType: string;
  locationType: LocationType | '';
}>;

const DEFAULT_DEVICE_PARAMETERS: DeviceParameters = {
  deviceId: '',
  coordinates: {},
  name: '',
  topDepth: DEFAULT_TOP_DEPTH,
  midDepth: 'disabled',
  botDepth: DEFAULT_BOT_DEPTH,
  cropType: '',
  locationType: '',
};

export type MoveDataLoggerCallback = (
  sensorId: string,
  deviceName: string | undefined,
  name: string,
  location: Coordinates,
  topDepth: number,
  midDepth: number | undefined,
  botDepth: number,
  locationType: LocationType | '',
  cropType: string
) => void;

type Props = Readonly<{
  moveDataLogger: MoveDataLoggerCallback;
  handleSettingsSave: (closeSettings: boolean) => void;
}>;

const MoveDeviceAccordion: React.FC<Props> = observer(
  ({ moveDataLogger, handleSettingsSave }: Props): JSX.Element => {
    const classes = useStyles();
    const intl = useIntl();

    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = useCallback(() => {
      setExpanded((value) => !value);
    }, [setExpanded]);

    const [stepNumber, setStepNumber] = useState(0);
    const incrementStepper = useCallback(() => setStepNumber((n) => n + 1), [setStepNumber]);
    const resetStepper = useCallback(() => setStepNumber(0), [setStepNumber]);

    const observationSiteStore = useObservationSiteStore();
    const devices = observationSiteStore.activeSiteIdentifiers.map(({ deviceIds }) => deviceIds);
    const geolocator = useGeolocator();
    const [
      { deviceId, coordinates, name, topDepth, midDepth, botDepth, cropType, locationType },
      setDeviceParameters,
    ] = useState(DEFAULT_DEVICE_PARAMETERS);
    const setDeviceId = useCallback(
      (deviceId: string) => setDeviceParameters((parameters) => ({ ...parameters, deviceId })),
      [setDeviceParameters]
    );
    const setCoordinates = useCallback(
      (coordinates: Partial<Coordinates>) => setDeviceParameters((parameters) => ({ ...parameters, coordinates })),
      [setDeviceParameters]
    );
    const setName = useCallback(
      (name: string) => setDeviceParameters((parameters) => ({ ...parameters, name })),
      [setDeviceParameters]
    );
    const setTopDepth = useCallback(
      (topDepth: number | undefined) => setDeviceParameters((parameters) => ({ ...parameters, topDepth })),
      [setDeviceParameters]
    );
    const setMidDepth = useCallback(
      (midDepth: MidDepthValue) => setDeviceParameters((parameters) => ({ ...parameters, midDepth })),
      [setDeviceParameters]
    );
    const setBotDepth = useCallback(
      (botDepth: number | undefined) => setDeviceParameters((parameters) => ({ ...parameters, botDepth })),
      [setDeviceParameters]
    );
    const setCropType = useCallback(
      (cropType: string) => setDeviceParameters((parameters) => ({ ...parameters, cropType })),
      [setDeviceParameters]
    );
    const setLocationType = useCallback(
      (locationType: LocationType) => setDeviceParameters((parameters) => ({ ...parameters, locationType })),
      [setDeviceParameters]
    );

    const originalLocationName = observationSiteStore.getSiteInfoByDeviceId(deviceId)?.site.name ?? '';

    // this should be simplified but the deviceType is not available on the siteInfo's device
    const selectedSiteInfo = observationSiteStore.getSiteInfoByDeviceId(deviceId);
    const deviceName = selectedSiteInfo?.deviceIds.deviceName;
    const firestore = useFirestore();
    const [deviceSnapshot] = useDocument(deviceName ? doc(firestore, `deviceNames/${deviceName}`) : null);
    const deviceNameObject = deviceSnapshot?.data();
    const isNbLogger = deviceNameObject?.deviceType === 'nbsensor';

    const onCancel = useCallback(() => {
      setExpanded(false);
      setTimeout(() => {
        geolocator.reset();
        setDeviceParameters(DEFAULT_DEVICE_PARAMETERS);
        resetStepper();
      }, 600); // delay to account for accordion closing animation
    }, [geolocator, resetStepper, setDeviceParameters]);

    const lowestDivRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (expanded) {
        setTimeout(() => {
          lowestDivRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 600);
      }
    }, [expanded, stepNumber]);

    const steps: readonly IStep[] = [
      goToInstallationStep(intl),
      {
        // title: 'Select device to move',
        title: intl.formatMessage({ id: 'select_device_to_move' }),
        content: <DeviceSelector devices={devices} deviceId={deviceId} setDeviceId={setDeviceId} />,
        canProceed: deviceId !== '',
      },
      {
        title: intl.formatMessage({ id: 'provide_coordinates' }),
        content: (
          <CoordinatesInput coordinates={coordinates} setCoordinates={setCoordinates} geolocator={geolocator} />
        ),
        canProceed: coordinates.lat != null && coordinates.lng != null,
      },
      isNbLogger ? turnOnNbLoggerStep(intl) : resetDataLoggerStep(intl),
      {
        title: intl.formatMessage({ id: 'configure_location_title' }),
        content: (
          <LocationConfigurationInput
            name={name}
            setName={setName}
            topDepth={topDepth}
            setTopDepth={setTopDepth}
            midDepth={midDepth}
            setMidDepth={setMidDepth}
            botDepth={botDepth}
            setBotDepth={setBotDepth}
            cropType={cropType}
            setCropType={setCropType}
            locationType={locationType}
            setLocationType={setLocationType}
          />
        ),
        canProceed:
          name.trim() !== '' &&
          Boolean(cropType) &&
          topDepth != undefined &&
          midDepth != undefined &&
          botDepth != undefined,
        onNext: async () => {
          const { lat, lng } = coordinates;
          if (!locationType) {
            throw new Error(intl.formatMessage({ id: 'location_type_not_selected' }));
          }
          if (
            lat != undefined &&
            lng != undefined &&
            topDepth != undefined &&
            midDepth != undefined &&
            botDepth != undefined &&
            Boolean(locationType)
          ) {
            moveDataLogger(
              deviceId,
              observationSiteStore.getSiteInfoByDeviceId(deviceId)?.deviceIds.deviceName,
              name,
              { lat, lng },
              topDepth,
              midDepth == 'disabled' ? undefined : midDepth,
              botDepth,
              locationType,
              cropType
            );
          }
        },
      },
      {
        title: intl.formatMessage({ id: 'done' }),
        content: intl.formatMessage({ id: 'previous_location_archived' }, { originalLocationName }),
        canProceed: true,
        onNext: async () => {
          handleSettingsSave(false);
        },
      },
    ];

    return (
      <>
        <Accordion
          primarySummary={intl.formatMessage({ id: 'move_device' })}
          expanded={expanded}
          expandIcon={<OpenWithIcon />}
          onSummaryClick={toggleExpanded}
        >
          <div className={classes.oneSensorWrapper}>
            <CustomStepper
              activeStep={stepNumber}
              steps={steps}
              incrementStepper={incrementStepper}
              onCancel={onCancel}
              unknownNumberOfSteps={false}
            />
          </div>
        </Accordion>
        <div ref={lowestDivRef} />
      </>
    );
  }
);

export default MoveDeviceAccordion;
