import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import LocalFloristTwoToneIcon from '@mui/icons-material/LocalFloristTwoTone';
import { Card, Collapse, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { GoogleMap, GroundOverlay, StandaloneSearchBox } from '@react-google-maps/api';
import { ErrorBoundary } from '@sentry/react';
import type { Coordinates } from '@soilsense/shared';
import { format } from 'date-fns';
import { isPointInPolygon } from 'geolib';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import type { SiteDetails } from '../../dataHandlers/ObservationSiteStore';
import { useCurrentUser, useFarmStore, useTokenProvider } from '../../dataHandlers/RootStore';
import { customerVisibleDeviceId } from '../../dataHandlers/utils/formatters';
import type { AreaId } from '../../interfaces/Area';
import type { FieldWithId } from '../../interfaces/Field';
import theme from '../../theme';
import { BASE_URL } from '../../utils/consts';
import {
  BRIGHT_NEUTRAL_COLOR,
  DARK_NEUTRAL_COLOR,
  getIrrigationStatus,
  getMarkerColorForIrrigationStatus,
  INTERACTION_COLOR,
  NEUTRAL_COLOR,
} from '../../utils/getMarkerColorFromReadings';
import type { AddFieldOverlayHandle } from './AddFieldOverlay';
import AddFieldOverlay from './AddFieldOverlay';
import { FieldShape } from './FieldShape';
import { MouseEventFriendlyMarker } from './MouseEventFriendlyMarker';

type NdviData = {
  metadata: {
    timestamp: number;
    satellite: string;
    coverage: number;
    clouds: number;
    sun: {
      elevation: number;
      azimuth: number;
    };
  };
  url: string;
  type: string;
  format: string;
};

type FieldNdviData = {
  fieldId: string;
  ndviData: NdviData[];
  bounds?: google.maps.LatLngBoundsLiteral;
};

type SoilMoistureData = {
  dt: number;
  moisture: number;
};

type FieldSoilData = {
  fieldId: string;
  soilData: SoilMoistureData[];
  bounds?: google.maps.LatLngBoundsLiteral;
};

const useStyles = makeStyles(() => ({
  innerContainer: {
    borderRadius: (props: { borderRadius: number }) => props.borderRadius,
    height: '100%',
    width: '100%',
  },
  largeButton: {
    padding: 20,
  },
  largeIcon: {
    fontSize: '2.5em',
  },
}));

const mapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: 'all',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];

interface IProps {
  siteDetails: readonly SiteDetails[];
  selectedArea?: AreaId;
  setSelectedArea?: React.Dispatch<AreaId | undefined>;
  handleSiteClick?: React.Dispatch<string>;
  handleFieldClick?: React.Dispatch<string>;
  borderRadius: number;
  zoomControl?: boolean;
  searchBoxVisible?: boolean;
  center: Coordinates | undefined;
  zoom: number;
  onZoomChange?: (zoom: number) => void;
  showNdviLayer?: boolean;
}

const NDVI_OPACITY = 1;

const DEFAULT_CENTER: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
const DEFAULT_ZOOM = 2;

const MarkersMap: FC<IProps> = observer(
  ({
    siteDetails,
    selectedArea,
    setSelectedArea,
    handleSiteClick,
    handleFieldClick,
    borderRadius,
    zoomControl = false,
    searchBoxVisible = false,
    center,
    zoom,
    onZoomChange,
    showNdviLayer = false,
  }) => {
    const farmStore = useFarmStore();

    if (!farmStore.selectedFarmId) {
      return null;
    }

    const classes = useStyles({ borderRadius });
    const farm = useFarmStore();
    const farmId = farm.selectedFarm?.id;
    const tokenProvider = useTokenProvider();
    const currentUser = useCurrentUser();
    const isAdmin = currentUser?.isAdmin;

    const [mapInstance, setMapInstance] = useState<google.maps.Map>();
    const mapOnLoad = useCallback((map: google.maps.Map) => {
      setMapInstance(map);
    }, []);
    const intl = useIntl();

    useEffect(() => {
      if (mapInstance != undefined && center != undefined && !isNaN(center.lat) && !isNaN(center.lng)) {
        if (mapInstance.getCenter() == undefined) {
          // forcefully initialize map center because panTo() does not have any
          // effect while the map instance is not initialized
          center && mapInstance.setCenter(center);
        } else {
          center && mapInstance.panTo(center);
        }
      } else {
        if (mapInstance != undefined && mapInstance.getCenter() == undefined) {
          mapInstance.panTo(DEFAULT_CENTER);
          mapInstance.setZoom(DEFAULT_ZOOM);
        }
      }
    }, [center, mapInstance]);

    const handleZoomChanged = useCallback(() => {
      const zoom = mapInstance?.getZoom();
      if (zoom !== undefined) {
        onZoomChange?.(zoom);
      }
    }, [mapInstance, onZoomChange]);

    const [ndviShown, setNdviShown] = useState(false);
    const [fieldNdviData, setFieldNdviData] = useState<FieldNdviData[]>([]);
    const [selectedNdviDate, setSelectedNdviDate] = useState(0);
    const [searchOpen, setSearchOpen] = useState(false);

    useEffect(() => {
      let active = true;
      (async () => {
        if (showNdviLayer && farmId && isAdmin) {
          try {
            const end = Math.floor(new Date('2024-09-01').getTime() / 1000);
            const start = Math.floor(new Date('2024-06-01').getTime() / 1000);

            const ndviPromises = farm.selectedFarmFields.map(async (field) => {
              const response = await fetch(
                `${BASE_URL}/agromonitoring/ndvi/${farmId}/${field.id}?start=${start}&end=${end}`,
                {
                  headers: {
                    Authorization: `Bearer ${await tokenProvider?.()}`,
                  },
                }
              );
              const data = await response.json();

              const bounds = {
                north: Math.max(...field.path.map((p) => p.lat)),
                south: Math.min(...field.path.map((p) => p.lat)),
                east: Math.max(...field.path.map((p) => p.lng)),
                west: Math.min(...field.path.map((p) => p.lng)),
              };

              console.log({ data });

              return {
                fieldId: field.id,
                ndviData: data || [],
                bounds,
              };
            });

            const results = await Promise.all(ndviPromises);
            if (active) {
              setFieldNdviData(results);
            }
          } catch (error) {
            console.error('Failed to fetch NDVI data:', error);
            setFieldNdviData([]);
          }
        } else {
          setFieldNdviData([]);
        }
      })();
      return () => {
        active = false;
      };
    }, [showNdviLayer, farmId, tokenProvider, farm.selectedFarmFields, isAdmin]);
    // }, [showNdviLayer]);

    // useEffect(() => {
    //   let active = true;
    //   if (availableNdviDates && availableNdviDates.length && selectedNdviDate != null) {
    //     (async () => {
    //       const { date } = availableNdviDates[selectedNdviDate];
    //       const mapid = await fetchNdviLayerId(date);
    //       if (active && mapid != undefined) {
    //         const eeMapOptions = {
    //           opacity: NDVI_OPACITY,
    //           getTileUrl: (tile: google.maps.Point, tileZoom: number) => {
    //             const url = [
    //               'https://earthengine.googleapis.com/v1alpha',
    //               mapid,
    //               'tiles',
    //               tileZoom,
    //               tile.x,
    //               tile.y,
    //             ].join('/');
    //             return url;
    //           },
    //           tileSize: google ? new google.maps.Size(256, 256) : undefined,
    //         };

    //         setNdviLayer(google ? new google.maps.ImageMapType(eeMapOptions) : undefined);
    //       }
    //     })();
    //   }
    //   return () => {
    //     active = false;
    //   };
    // }, [availableNdviDates, selectedNdviDate]);

    // useEffect(() => {
    //   if (ndviShown) {
    //     mapInstance?.overlayMapTypes.clear();
    //     if (ndviLayer != null) {
    //       mapInstance?.overlayMapTypes.push(ndviLayer);
    //     }
    //   }
    // }, [ndviLayer, mapInstance?.overlayMapTypes, ndviShown]);

    const toggleNdvi = () => {
      setNdviShown((prev) => !prev);
    };

    const NdviNavigation = () => (
      <Card
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          color: 'black',
          height: 100,
          width: 100,
        }}
      >
        <div style={{ textAlign: 'center', fontWeight: 900, height: 23, paddingTop: 5 }}>NDVI</div>
        <div style={{ textAlign: 'center' }}>
          <IconButton
            onClick={() => {
              setSelectedNdviDate((prev) => Math.max(prev - 1, 0));
            }}
            size='large'
          >
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              const maxIndex = Math.min(...fieldNdviData.map((field) => field.ndviData.length - 1));
              setSelectedNdviDate((prev) => Math.min(prev + 1, Math.max(maxIndex, 0)));
            }}
            size='large'
          >
            <ChevronRightIcon />
          </IconButton>
        </div>
        {fieldNdviData.length > 0 &&
          fieldNdviData[0].ndviData.length > selectedNdviDate &&
          fieldNdviData[0].ndviData[selectedNdviDate] && (
            <div style={{ textAlign: 'center' }}>
              {format(fieldNdviData[0].ndviData[selectedNdviDate].metadata.timestamp * 1000, 'yyyy-MM-dd')}
            </div>
          )}
      </Card>
    );

    const [fieldDraft, setFieldDraft] = useState<FieldWithId>();
    // make a copy of the field draft coordinates just to make geolib types happy
    const fieldDraftPath = useMemo(() => (fieldDraft == undefined ? [] : [...fieldDraft.path]), [fieldDraft]);
    const addFieldOverlayRef = useRef<AddFieldOverlayHandle | null>(null);

    const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox | null>(null);

    const onSearchBoxLoad = (ref: google.maps.places.SearchBox) => {
      setSearchBox(ref);
    };

    const onPlacesChanged = () => {
      if (searchBox) {
        const places = searchBox.getPlaces();
        // setSearchResults(places || []);
        setSearchOpen(false);
        if (places && places.length > 0 && places[0].geometry && places[0].geometry.location) {
          mapInstance?.panTo(places[0].geometry.location);
          mapInstance?.setZoom(15);
        }
      }
    };

    const handleSearch = (value: string) => {
      const coords = parseCoordinates(value);
      if (coords) {
        mapInstance?.panTo(coords);
        mapInstance?.setZoom(15);
      } else {
        // Existing place search logic
        if (searchBox) {
          const places = searchBox.getPlaces();
          // setSearchResults(places || []);
          if (places && places.length > 0 && places[0].geometry && places[0].geometry.location) {
            mapInstance?.panTo(places[0].geometry.location);
            mapInstance?.setZoom(15);
          }
        }
      }
    };

    // const [fieldSoilData, setFieldSoilData] = useState<FieldSoilData[]>([]);

    // console.log({ fieldSoilData });

    // useEffect(() => {
    //   let active = true;
    //   (async () => {
    //     if (fieldSoilData.length > 0) return;
    //     if (farmId) {
    //       try {
    //         const soilPromises = farm.selectedFarmFields.map(async (field) => {
    //           const response = await fetch(`${BASE_URL}/agromonitoring/soil/${farmId}/${field.id}`, {
    //             headers: {
    //               Authorization: `Bearer ${await tokenProvider?.()}`,
    //             },
    //           });
    //           const data = await response.json();

    //           const bounds = {
    //             north: Math.max(...field.path.map((p) => p.lat)),
    //             south: Math.min(...field.path.map((p) => p.lat)),
    //             east: Math.max(...field.path.map((p) => p.lng)),
    //             west: Math.min(...field.path.map((p) => p.lng)),
    //           };

    //           console.log('Soil moisture data:', { fieldId: field.id, data });

    //           return {
    //             fieldId: field.id,
    //             soilData: data || [],
    //             bounds,
    //           };
    //         });

    //         const results = await Promise.all(soilPromises);
    //         if (active) {
    //           setFieldSoilData(results);
    //         }
    //       } catch (error) {
    //         console.error('Failed to fetch soil moisture data:', error);
    //         setFieldSoilData([]);
    //       }
    //     } else {
    //       setFieldSoilData([]);
    //     }
    //   })();
    //   return () => {
    //     active = false;
    //   };
    // }, [farmId, tokenProvider, farm.selectedFarmFields, fieldSoilData.length]);

    return (
      <ErrorBoundary fallback={<div>Error loading map</div>}>
        {searchBoxVisible && (
          <div style={{ position: 'absolute', top: 15, left: 15, zIndex: 1000 }}>
            {!searchOpen ? (
              <IconButton
                className='search-icon-button'
                style={{
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  padding: 10,
                }}
                onClick={() => {
                  setSearchOpen(true);
                }}
              >
                <SearchIcon className='search-icon' />
              </IconButton>
            ) : (
              <>
                <Collapse in={searchOpen} orientation='horizontal' timeout={500}>
                  <StandaloneSearchBox onLoad={onSearchBoxLoad} onPlacesChanged={onPlacesChanged}>
                    <TextField
                      variant='outlined'
                      placeholder={intl.formatMessage({ id: 'search_for_a_location_or_enter_coordinates' })}
                      style={{
                        width: searchOpen ? '90vw' : 0,
                        maxWidth: 400,
                        backgroundColor: 'white',
                        borderRadius: '20px',
                        transition: 'width 0.5s ease-in-out',
                      }}
                      InputProps={{
                        type: 'text',
                        style: {
                          borderRadius: '20px',
                        },
                        endAdornment: (
                          <IconButton onClick={() => setSearchOpen(false)} edge='end'>
                            <CloseIcon />
                          </IconButton>
                        ),
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch((e.target as HTMLInputElement).value);
                          setSearchOpen(false);
                        }
                      }}
                      autoFocus
                    />
                  </StandaloneSearchBox>
                </Collapse>
                <style>
                  {`
                  .pac-container {
                    margin-left: 15px !important;
                    border-radius: 10px !important;
                    border-top-left-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                    margin-top: -1px !important;
                    cursor: pointer !important;
                    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.1) !important;
                    // left: 15px !important;
                    // width: calc(90vw - 30px) !important;
                    // max-width: 370px !important;
                  }
                `}
                </style>
              </>
            )}
          </div>
        )}
        <GoogleMap
          mapContainerClassName={classes.innerContainer}
          zoom={zoom}
          onZoomChanged={handleZoomChanged}
          options={{
            zoomControl,
            styles: mapStyles,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            mapTypeId: google ? google.maps.MapTypeId.HYBRID : 'hybrid',
          }}
          onLoad={mapOnLoad}
          onClick={(event) => {
            const { latLng } = event;
            if (latLng != undefined) {
              const action = addFieldOverlayRef.current?.handleMapClick({
                lat: latLng.lat(),
                lng: latLng.lng(),
              });
              if (action == 'stop') {
                event.stop();
              } else {
                setSelectedArea?.(undefined);
              }
            }
          }}
        >
          {/* Top right controls */}
          <div
            style={{
              position: 'absolute',
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(1),
              alignItems: 'flex-end',
            }}
          >
            {setSelectedArea != undefined && (
              <AddFieldOverlay
                ref={addFieldOverlayRef}
                fieldDraft={fieldDraft}
                setFieldDraft={setFieldDraft}
                setSelectedArea={setSelectedArea}
              />
            )}
            {isAdmin && showNdviLayer && (
              <div style={{ display: 'flex', gap: '10px' }}>
                {ndviShown && <NdviNavigation />}
                {showNdviLayer && (
                  <IconButton
                    className={classes.largeButton}
                    color='inherit'
                    aria-label=''
                    onClick={toggleNdvi}
                    edge='start'
                    size='large'
                  >
                    {ndviShown ? (
                      <LocalFloristIcon className={classes.largeIcon} />
                    ) : (
                      <LocalFloristTwoToneIcon className={classes.largeIcon} />
                    )}
                  </IconButton>
                )}
              </div>
            )}
          </div>

          {farmStore.selectedFarmFields.map((field) => (
            <FieldShape
              key={field.id}
              field={field}
              center={field.center}
              strokeColor={BRIGHT_NEUTRAL_COLOR}
              fillColor={field.color}
              zoom={zoom}
              selected={selectedArea?.kind == 'field' && selectedArea.fieldId == field.id}
              onClick={() => handleFieldClick?.(field.id)}
            />
          ))}
          {siteDetails.map((details) => {
            const coordinates = toJS(details.site.coordinates);
            const color =
              fieldDraft == undefined
                ? getMarkerColorForIrrigationStatus(getIrrigationStatus(details))
                : details.fieldId == undefined && isPointInPolygon(coordinates, fieldDraftPath)
                ? INTERACTION_COLOR
                : NEUTRAL_COLOR;
            return (
              <MouseEventFriendlyMarker
                key={details.site.id}
                coordinates={coordinates}
                fill={color}
                fillOpacity={fieldDraft == undefined ? 1.0 : 0.7}
                stroke={BRIGHT_NEUTRAL_COLOR}
                strokeWidth={1}
                label={
                  zoom >= 16 && (details.fieldId == undefined || zoom >= 18)
                    ? customerVisibleDeviceId(details.deviceIds)
                    : undefined
                }
                labelColor={color == INTERACTION_COLOR ? DARK_NEUTRAL_COLOR : BRIGHT_NEUTRAL_COLOR}
                highlighted={
                  (selectedArea?.kind == 'site' && details.site.id == selectedArea.siteId) ||
                  (selectedArea?.kind == 'field' && details.fieldId == selectedArea.fieldId)
                }
                handleClick={
                  fieldDraft == undefined
                    ? () => {
                        if (details.fieldId == undefined) {
                          handleSiteClick?.(details.site.id);
                        } else {
                          handleFieldClick?.(details.fieldId);
                        }
                      }
                    : undefined
                }
              />
            );
          })}
          {ndviShown &&
            fieldNdviData.map(
              (fieldData) =>
                fieldData.ndviData.length > 0 &&
                fieldData.bounds && (
                  <GroundOverlay
                    key={`${fieldData.fieldId}-${selectedNdviDate}`}
                    url={fieldData.ndviData[selectedNdviDate].url}
                    bounds={
                      new google.maps.LatLngBounds(
                        new google.maps.LatLng(fieldData.bounds.south, fieldData.bounds.west),
                        new google.maps.LatLng(fieldData.bounds.north, fieldData.bounds.east)
                      )
                    }
                    opacity={NDVI_OPACITY}
                  />
                )
            )}
        </GoogleMap>
      </ErrorBoundary>
    );
  }
);

function parseCoordinates(input: string): google.maps.LatLngLiteral | null {
  const parts = input.split(',').map((part) => parseFloat(part.trim()));
  if (parts.length === 2 && !isNaN(parts[0]) && !isNaN(parts[1])) {
    const [lat, lng] = parts;
    if (isLatLngLiteral({ lat, lng })) {
      return { lat, lng };
    }
  }
  return null;
}

export default MarkersMap;
