import { useCallback } from 'react';
import useAuthToken from '../../../utils/useAuthToken';

export const useFetchWithAuth = () => {
  const getIdToken = useAuthToken();

  return useCallback(
    async (url: string, options: RequestInit) => {
      const token = await getIdToken();
      return fetch(url, {
        ...options,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          ...options.headers,
        },
      });
    },
    [getIdToken]
  );
};
