import { Card } from '@mui/material';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React from 'react';
import type { SiteDetails } from '../../dataHandlers/ObservationSiteStore';
import type { IMinMax } from '../../dataHandlers/SensorTransformer';
import useIsMobile from '../../utils/useIsMobile';
import useTraceUpdate from '../../utils/useTraceUpdate';
import { ZoomableChartGroup } from './Chart/ZoomableChartGroup';
import MapIcon from './MapIcon';

type Props = Readonly<{
  tag: string | number;
  siteDetails: SiteDetails;
  scaleLoading: boolean;
  scale: IMinMax | undefined;
  displaySalinity: boolean;
  displayTemperature: boolean;
  displayAirTemperature: boolean;
  displayPrecipitation: boolean;
  openDialog?: (sensorId: string) => void;
}>;

export const SensorDataCard: FC<Props> = observer((props) => {
  const {
    tag,
    siteDetails,
    scaleLoading,
    scale,
    displaySalinity,
    displayTemperature,
    displayAirTemperature,
    displayPrecipitation,
    openDialog,
  } = props;
  useTraceUpdate(props, `SensorDataCard ${tag} ${siteDetails.deviceIds.id}`);
  const isMobile = useIsMobile();
  return (
    <Card
      style={
        isMobile
          ? {
              borderRadius: 0,
            }
          : {}
      }
    >
      <ZoomableChartGroup
        siteDetails={siteDetails}
        titleFontColor='textPrimary'
        scaleLoading={scaleLoading}
        scale={scale}
        onSettingsClick={openDialog}
        displaySalinity={displaySalinity}
        displayTemperature={displayTemperature}
        displayAirTemperature={displayAirTemperature}
        displayPrecipitation={displayPrecipitation}
      >
        <MapIcon siteDetails={siteDetails} />
      </ZoomableChartGroup>
    </Card>
  );
});
