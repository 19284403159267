import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import moment from 'moment';
import type { RequiredActionFunctionToolCall } from 'openai/resources/beta/threads/runs/runs.mjs';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFarmStore } from '../dataHandlers/RootStore';
import theme from '../theme';
import { getWeatherForecastLatLon } from '../utils/getWeather';
import Chat from './LLM/Chat';

interface MagicDialogContextType {
  openMagicDialog: (siteId: string) => void;
}

const MagicDialogContext = createContext<MagicDialogContextType | undefined>(undefined);

export const MagicDialogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState<string>('');
  const [chatKey, setChatKey] = useState(0);
  const farmStore = useFarmStore();
  const [cropType, setCropType] = useState<string | null>(null);
  const [depths, setDepths] = useState<{ topDepth: number; bottomDepth: number } | null>(null);

  const selectedSite = useMemo(() => {
    if (!selectedSiteId) return undefined;

    // Find site in field sites
    const fields = farmStore.areas.filter((area) => area.kind === 'field');
    const fieldSite = fields
      .flatMap((field) => field.fieldDetails.siteDetails)
      .find((site) => site.site.id === selectedSiteId);
    if (fieldSite) {
      return {
        kind: 'site' as const,
        siteDetails: fieldSite,
      };
    }

    // Find standalone site
    const site = farmStore.areas.find(
      (area) => area.kind === 'site' && area.siteDetails.site.id === selectedSiteId
    );
    if (site?.kind === 'site') {
      return site;
    }

    return undefined;
  }, [farmStore.areas, selectedSiteId]);
  console.log('selectedSite', { selectedSite });

  useEffect(() => {
    if (selectedSite) {
      setCropType(selectedSite.siteDetails.site.cropType ?? '');
      setDepths({
        topDepth: selectedSite.siteDetails.configuration.cableTop.depth,
        bottomDepth: selectedSite.siteDetails.configuration.cableBottom.depth,
      });
    }
  }, [selectedSite]);

  const openMagicDialog = useCallback((siteId: string) => {
    console.log('openMagicDialog', { siteId });
    setSelectedSiteId(siteId);
    setOpen(true);
    setChatKey((prev) => prev + 1);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const getData = useCallback(async () => {
    const site = farmStore.areas
      .filter((area) => area.kind === 'site')
      .find((area) => area.siteDetails.site.id === selectedSiteId);

    if (site) {
      const details = site.siteDetails;
      if (details.transformed.status === 'ready') {
        return details.transformed.value;
      }
    }

    const field = farmStore.areas
      .filter((area) => area.kind === 'field')
      .find((area) => area.fieldDetails.siteDetails.some((site) => site.site.id === selectedSiteId));

    if (field) {
      const details = field.fieldDetails.siteDetails.find((site) => site.site.id === selectedSiteId);
      if (details?.transformed.status === 'ready') {
        return details.transformed.value;
      }
    }

    return undefined;
  }, [farmStore.areas, selectedSiteId]);

  const getDepthsAndCropInfo = useCallback(async (): Promise<{
    topDepth?: number;
    bottomDepth?: number;
    crop?: string;
    safeRangeHigh?: number;
    safeRangeLow?: number;
  }> => {
    let details;
    const site = farmStore.areas
      .filter((area) => area.kind === 'site')
      .find((area) => area.siteDetails.site.id === selectedSiteId);

    if (!site) {
      const field = farmStore.areas
        .filter((area) => area.kind === 'field')
        .find((area) => area.fieldDetails.siteDetails.some((site) => site.site.id === selectedSiteId));
      if (!field) return { topDepth: undefined, bottomDepth: undefined, crop: undefined };
      details = field.fieldDetails.siteDetails.find((site) => site.site.id === selectedSiteId);
    } else {
      details = site.siteDetails;
    }

    if (!details)
      return {
        topDepth: undefined,
        bottomDepth: undefined,
        crop: undefined,
        safeRangeHigh: undefined,
        safeRangeLow: undefined,
      };

    const topDepth = details.configuration.cableTop.depth;
    const bottomDepth = details.configuration.cableBottom.depth;
    const crop = details.site.cropType;
    const safeRangeHigh = details.site.safeRanges.plantAvailableWater?.[1];
    const safeRangeLow = details.site.safeRanges.plantAvailableWater?.[0];

    return { topDepth, bottomDepth, crop, safeRangeHigh, safeRangeLow };
  }, [farmStore.areas, selectedSiteId]);

  const functionCallHandler = useCallback(
    async (call: RequiredActionFunctionToolCall) => {
      console.log('functionCallHandler', { call });
      if (call?.function?.name === 'get_weather_forecast') {
        const args = JSON.parse(call.function.arguments as string);
        if (!selectedSite) return JSON.stringify({});

        const data = await getWeatherForecastLatLon(
          selectedSite.siteDetails.site.coordinates.lat,
          selectedSite.siteDetails.site.coordinates.lng
        );
        return JSON.stringify(data);
      }
      if (call?.function?.name === 'get_soil_moisture_data') {
        console.log('CALLED get_soil_moisture_data');
        // farmStore.setSelectedDates(moment().subtract(7, 'days').startOf('day'), moment().endOf('day'));

        await new Promise((resolve) => {
          const interval = setInterval(() => {
            if (
              farmStore.areas
                .filter((area) => area.kind == 'site')
                .every((area) => area.siteDetails.transformed.status == 'ready')
            ) {
              clearInterval(interval);
              resolve(true);
            }
            if (
              farmStore.areas
                .filter((area) => area.kind == 'site')
                .some((area) => area.siteDetails.transformed.status == 'error')
            ) {
              clearInterval(interval);
              resolve(true);
            }
          }, 500);
        });

        const data = await getData();
        console.log('data', { data });
        if (!data?.data) return JSON.stringify({});

        const { topDepth, bottomDepth, crop, safeRangeHigh, safeRangeLow } = await getDepthsAndCropInfo();

        console.log('topDepth', { topDepth });
        console.log('bottomDepth', { bottomDepth });
        console.log('crop', { crop });

        const isNumber = (value: any): value is number => typeof value === 'number';

        // Group data by day
        const dailyStats = data.data.reduce(
          (
            acc: Record<
              string,
              {
                pawTop: { min: number | '-'; max: number | '-'; sum: number | '-'; count: number };
                pawBot: { min: number | '-'; max: number | '-'; sum: number | '-'; count: number };
                vwcTop: { min: number | '-'; max: number | '-'; sum: number | '-'; count: number };
                vwcBot: { min: number | '-'; max: number | '-'; sum: number | '-'; count: number };
              }
            >,
            item
          ) => {
            const day = moment(item.timestamp).format('YYYY-MM-DD');
            if (!item.pawBot && !item.pawTop && !item.vwcBot && !item.vwcTop) return acc;

            if (!acc[day]) {
              acc[day] = {
                pawTop: {
                  min: item.pawTop ?? '-',
                  max: item.pawTop ?? '-',
                  sum: item.pawTop ?? '-',
                  count: 1,
                },
                pawBot: {
                  min: item.pawBot ?? '-',
                  max: item.pawBot ?? '-',
                  sum: item.pawBot ?? '-',
                  count: 1,
                },
                vwcTop: {
                  min: item.vwcTop ?? '-',
                  max: item.vwcTop ?? '-',
                  sum: item.vwcTop ?? '-',
                  count: 1,
                },
                vwcBot: {
                  min: item.vwcBot ?? '-',
                  max: item.vwcBot ?? '-',
                  sum: item.vwcBot ?? '-',
                  count: 1,
                },
              };
            } else {
              acc[day] = {
                pawTop: {
                  min:
                    isNumber(acc[day].pawTop.min) && isNumber(item.pawTop ?? '-')
                      ? Math.min(acc[day].pawTop.min, item.pawTop!)
                      : acc[day].pawTop.min,
                  max:
                    isNumber(acc[day].pawTop.max) && isNumber(item.pawTop ?? '-')
                      ? Math.max(acc[day].pawTop.max, item.pawTop!)
                      : acc[day].pawTop.max,
                  sum:
                    isNumber(acc[day].pawTop.sum) && isNumber(item.pawTop ?? '-')
                      ? acc[day].pawTop.sum + item.pawTop!
                      : acc[day].pawTop.sum,
                  count: acc[day].pawTop.count + 1,
                },
                pawBot: {
                  min:
                    isNumber(acc[day].pawBot.min) && isNumber(item.pawBot ?? '-')
                      ? Math.min(acc[day].pawBot.min, item.pawBot!)
                      : acc[day].pawBot.min,
                  max:
                    isNumber(acc[day].pawBot.max) && isNumber(item.pawBot ?? '-')
                      ? Math.max(acc[day].pawBot.max, item.pawBot!)
                      : acc[day].pawBot.max,
                  sum:
                    isNumber(acc[day].pawBot.sum) && isNumber(item.pawBot ?? '-')
                      ? acc[day].pawBot.sum + item.pawBot!
                      : acc[day].pawBot.sum,
                  count: acc[day].pawBot.count + 1,
                },
                vwcTop: {
                  min:
                    isNumber(acc[day].vwcTop.min) && isNumber(item.vwcTop ?? '-')
                      ? Math.min(acc[day].vwcTop.min, item.vwcTop!)
                      : acc[day].vwcTop.min,
                  max:
                    isNumber(acc[day].vwcTop.max) && isNumber(item.vwcTop ?? '-')
                      ? Math.max(acc[day].vwcTop.max, item.vwcTop!)
                      : acc[day].vwcTop.max,
                  sum:
                    isNumber(acc[day].vwcTop.sum) && isNumber(item.vwcTop ?? '-')
                      ? acc[day].vwcTop.sum + item.vwcTop!
                      : acc[day].vwcTop.sum,
                  count: acc[day].vwcTop.count + 1,
                },
                vwcBot: {
                  min:
                    isNumber(acc[day].vwcBot.min) && isNumber(item.vwcBot ?? '-')
                      ? Math.min(acc[day].vwcBot.min, item.vwcBot!)
                      : acc[day].vwcBot.min,
                  max:
                    isNumber(acc[day].vwcBot.max) && isNumber(item.vwcBot ?? '-')
                      ? Math.max(acc[day].vwcBot.max, item.vwcBot!)
                      : acc[day].vwcBot.max,
                  sum:
                    isNumber(acc[day].vwcBot.sum) && isNumber(item.vwcBot ?? '-')
                      ? acc[day].vwcBot.sum + item.vwcBot!
                      : acc[day].vwcBot.sum,
                  count: acc[day].vwcBot.count + 1,
                },
              };
            }
            return acc;
          },
          {}
        );

        const dailyData = Object.entries(dailyStats).map(([date, stats]) => ({
          date,
          crop: `I am growing ${crop}`,
          description: {
            pawTop: `Plant available water (% of field capacity) at depth ${
              topDepth ? `${topDepth}cm` : 'surface soil'
            } ('-' if not available)`,
            pawBot: `Plant available water (% of field capacity) at depth ${
              bottomDepth ? `${bottomDepth}cm` : 'deep soil'
            } ('-' if not available)`,
            vwcTop: `Volumetric water content (%) at depth ${
              topDepth ? `${topDepth}cm` : 'surface soil'
            } ('-' if not available)`,
            vwcBot: `Volumetric water content (%) at depth ${
              bottomDepth ? `${bottomDepth}cm` : 'low soil'
            } ('-' if not available)`,
            pawSafeRangeHigh: `Plant available water safe range high (${safeRangeHigh}%)`,
            pawSafeRangeLow: `Plant available water safe range low (${safeRangeLow}%)`,
          },
          pawTop: {
            min: stats.pawTop.min,
            max: stats.pawTop.max,
            avg:
              isNumber(stats.pawTop.sum) && isNumber(stats.pawTop.count)
                ? stats.pawTop.sum / stats.pawTop.count
                : '-',
          },
          pawBot: {
            min: stats.pawBot.min,
            max: stats.pawBot.max,
            avg:
              isNumber(stats.pawBot.sum) && isNumber(stats.pawBot.count)
                ? stats.pawBot.sum / stats.pawBot.count
                : '-',
          },
          vwcTop: {
            min: stats.vwcTop.min,
            max: stats.vwcTop.max,
            avg:
              isNumber(stats.vwcTop.sum) && isNumber(stats.vwcTop.count)
                ? stats.vwcTop.sum / stats.vwcTop.count
                : '-',
          },
          vwcBot: {
            min: stats.vwcBot.min,
            max: stats.vwcBot.max,
            avg:
              isNumber(stats.vwcBot.sum) && isNumber(stats.vwcBot.count)
                ? stats.vwcBot.sum / stats.vwcBot.count
                : '-',
          },
        }));

        return JSON.stringify(dailyData);
      }
      return Promise.resolve('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSite]
  );

  return (
    <MagicDialogContext.Provider value={{ openMagicDialog }}>
      {children}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: { xs: 'calc(100% - 8px)', sm: 'calc(100% - 24px)', md: theme.breakpoints.values.md },
            maxHeight: { xs: 'calc(100% - 16px)', sm: 'calc(100% - 48px)' },
            margin: { xs: '8px', sm: '24px' },
            padding: { xs: '2px', sm: '16px' },
            width: { xs: '100%', sm: 'calc(100% - 48px)' },
          },
        }}
      >
        <DialogTitle>
          Soil Moisture Analysis{' '}
          {`${selectedSiteId ? `for ${selectedSite?.siteDetails.site.name} (${cropType})` : ''}`}
        </DialogTitle>
        <DialogContent
          sx={{
            height: '600px',
            display: 'flex',
            flexDirection: 'column',
            padding: { xs: '4px', sm: '8px' },
          }}
        >
          {cropType !== null && (
            <Chat
              key={chatKey}
              functionCallHandler={functionCallHandler}
              initialMessage={`Please summarize my soil moisture data. If needed, accomplish this by performing 3 functions calls: get soil moisture data, get weather forecast. Before fetching the weather forecast and soil moisture data, search your knowledge base (provided documents) for relevant information. If possible draw some concise conclusions that I can use to make decisions. ${
                cropType !== '-'
                  ? `Find information relevant to growing ${cropType}. Prioritize Plant Available Water (PAW) as the source of moisture information.`
                  : ''
              }`}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            padding: { xs: '4px', sm: '8px' },
          }}
        >
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </MagicDialogContext.Provider>
  );
};

export const useMagicDialog = () => {
  const context = useContext(MagicDialogContext);
  if (!context) {
    throw new Error('useMagicDialog must be used within a MagicDialogProvider');
  }
  return context;
};
