import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCurrentUser, useFarmStore, useUserStore } from '../../dataHandlers/RootStore';

interface SetupDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const SetupDialog: React.FC<SetupDialogProps> = ({ isOpen, onClose }) => {
  const intl = useIntl();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [notificationChannel, setNotificationChannel] = useState<'whatsapp' | 'sms'>('sms');
  const userStore = useUserStore();
  const currentUser = useCurrentUser()!;
  const farmStore = useFarmStore();
  const currentFarm = farmStore.selectedFarm;
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    if (isOpen) {
      userStore.updateUserSettings({ showSetupDialog: false });
      userStore.doUpdateUserSettings(currentUser.uid, { showSetupDialog: false });
    }
  }, [isOpen, userStore, currentUser.uid]);

  const validatePhoneNumber = React.useCallback((number: string) => {
    // Check for format: +XX... (country code followed by number)
    const phoneRegex = /^\+\d{1,3}\d{9,14}$/;
    return phoneRegex.test(number.replace(/[\s-]/g, ''));
  }, []);

  const handleSave = React.useCallback(async () => {
    if (!validatePhoneNumber(phoneNumber)) {
      setPhoneError(
        intl.formatMessage({
          id: 'invalid_phone_number',
          defaultMessage: 'Please enter a valid phone number including country code (e.g., +1234567890)',
        })
      );
      return;
    }

    // save phone number on farm
    if (currentFarm) {
      const currentPhoneNumbers = currentFarm.settings?.phoneNumbers ?? [];
      const currentUserIsOwner = currentFarm.ownerId === currentUser.uid;
      const initSettings = {
        enableInstantNotifications: true,
        instantNotificationChannel: notificationChannel,
      };
      const phoneNumberStripped = phoneNumber.replace(/\s/g, '');
      await farmStore.updateFarmPartial(currentFarm.id, {
        settings: {
          ...(currentUserIsOwner ? initSettings : farmStore.selectedFarm.settings),
          phoneNumbers: Array.from(new Set([...currentPhoneNumbers, phoneNumberStripped])),
        },
      });
      // await farmStore.updateAvailableFarms();
    }
    onClose();
  }, [
    phoneNumber,
    notificationChannel,
    validatePhoneNumber,
    intl,
    currentFarm,
    currentUser.uid,
    farmStore,
    onClose,
  ]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {intl.formatMessage(
          {
            id: 'welcome_to_farm',
            defaultMessage: 'Welcome to the {farmName}!',
          },
          {
            farmName: currentFarm?.name || '',
          }
        )}
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant='body1' style={{ marginBottom: '20px' }}>
          {intl.formatMessage({
            id: 'setup_instructions',
            defaultMessage:
              "Behind the scenes, we make sense of your data so that you don't have to. To let you know when your plant needs more water, we need your phone number.",
          })}
        </Typography>
        <Typography variant='body1' style={{ marginBottom: '20px' }}>
          {intl.formatMessage({
            id: 'enabled_notifications',
            defaultMessage:
              'We will enable notifications for you. You can change this anytime by clicking on the settings icon next to the farm name.',
          })}
        </Typography>
        <FormControl component='fieldset' style={{ marginBottom: '20px' }}>
          {/* <FormLabel component='legend'>
            {intl.formatMessage({
              id: 'settings_instant_notifications_channel',
              defaultMessage: 'How would you like to receive notifications?',
            })}
          </FormLabel> */}
          <RadioGroup
            value={notificationChannel}
            onChange={(e) => setNotificationChannel(e.target.value as 'whatsapp' | 'sms')}
          >
            <FormControlLabel
              value='whatsapp'
              control={<Radio />}
              label={
                <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <WhatsAppIcon color='success' />
                  {intl.formatMessage({ id: 'WhatsApp', defaultMessage: 'WhatsApp' })}
                </span>
              }
            />
            <FormControlLabel
              value='sms'
              control={<Radio />}
              label={
                <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <PhoneIcon color='primary' />
                  {intl.formatMessage({ id: 'SMS', defaultMessage: 'SMS' })}
                </span>
              }
            />
          </RadioGroup>
        </FormControl>
        <TextField
          label={intl.formatMessage({ id: 'phone_number', defaultMessage: 'Phone Number' })}
          value={phoneNumber}
          onChange={(e) => {
            const phoneNumberStripped = e.target.value.replace(/[^\d+]/g, '');
            setPhoneNumber(phoneNumberStripped);
            setPhoneError('');
          }}
          error={!!phoneError}
          helperText={
            phoneError ||
            intl.formatMessage({
              id: 'phone_number_format',
              defaultMessage: 'Format: +[country code][number]',
            })
          }
          placeholder='+12345678901'
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {intl.formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
        </Button>
        <Button onClick={handleSave} color='primary'>
          {intl.formatMessage({ id: 'save', defaultMessage: 'Save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SetupDialog;
