import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { type FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import cropsImage from '../../assets/crops.jpg';
import { useCurrentUser, useFarmStore, useUserStore } from '../../dataHandlers/RootStore';
import useIsMobile from '../../utils/useIsMobile';

interface NoFarmPermissionsDialogProps {
  isOpen: boolean;
}

const NoFarmPermissionsDialog: FC<NoFarmPermissionsDialogProps> = observer(({ isOpen }) => {
  const [_isWaitingForPermissions, setIsWaitingForPermissions] = useState(true);
  const [newFarmName, setNewFarmName] = useState('');
  const [isCreatingFarm, setIsCreatingFarm] = useState(false);
  const currentUser = useCurrentUser()!;

  const farmStore = useFarmStore();
  const userStore = useUserStore();
  const intl = useIntl();
  const isMobile = useIsMobile();

  const isWaitingForPermissions = useMemo(() => {
    console.log('isWaitingForPermissions', _isWaitingForPermissions, farmStore.isLoading);
    return _isWaitingForPermissions || farmStore.isLoading;
  }, [_isWaitingForPermissions, farmStore.isLoading]);

  console.log('isWaitingForPermissions', isWaitingForPermissions);

  useEffect(() => {
    if (isOpen) {
      setIsWaitingForPermissions(true);
      const timer = setTimeout(() => {
        setIsWaitingForPermissions(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleCreateFarm = async () => {
    if (!newFarmName) return;

    setIsCreatingFarm(true);
    try {
      const confirmation = await farmStore.addFarm(newFarmName, currentUser.uid, currentUser.email ?? '');
      if (confirmation) {
        await userStore.updateUser(currentUser.uid, {
          farmPermissions: [
            {
              id: confirmation.id,
              isAdmin: true,
            },
          ],
        });
      }
    } catch (error) {
      console.error('Failed to create farm:', error);
    }
    setTimeout(() => {
      setIsCreatingFarm(false);
    }, 1000);
  };

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={() => {}}
      PaperProps={{
        style: {
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
        },
      }}
      componentsProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            backdropFilter: 'blur(8px)',
          },
        },
      }}
    >
      <DialogTitle>
        {isWaitingForPermissions
          ? intl.formatMessage({ id: 'loading_permissions' })
          : intl.formatMessage({ id: 'create_first_farm' })}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {isWaitingForPermissions ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <CircularProgress />
            <p>{intl.formatMessage({ id: 'loading_permissions' })}</p>
          </div>
        ) : (
          <div
            // sx={{
            //   padding: '20px',
            //   display: 'flex',
            //   flexDirection: { xs: 'column', sm: 'row' },
            //   gap: '20px',
            // }}]
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: '20px',
            }}
          >
            <img
              src={cropsImage}
              alt='Crops'
              // sx={{
              //   marginTop: 5,
              //   height: '205px',
              //   width: { xs: '100%', sm: '205px' },
              //   maxWidth: '205px',
              //   objectFit: 'cover',
              //   borderRadius: '12px',
              //   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              //   alignSelf: { xs: 'center', sm: 'flex-start' },
              // }}
              style={{
                marginTop: 5,
                height: '205px',
                width: isMobile ? '100%' : '205px',
                maxWidth: '205px',
                objectFit: 'cover',
                borderRadius: '12px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                alignSelf: isMobile ? 'center' : 'flex-start',
              }}
            />
            <div>
              <Typography variant='body1'>{intl.formatMessage({ id: 'no_farm_access_message' })}</Typography>
              <TextField
                fullWidth
                label='Farm Name'
                value={newFarmName}
                onChange={(e) => setNewFarmName(e.target.value)}
                margin='normal'
              />
              <Button
                fullWidth
                variant='contained'
                color='primary'
                onClick={handleCreateFarm}
                disabled={!newFarmName || isCreatingFarm}
                style={{ marginTop: '20px' }}
              >
                {isCreatingFarm ? <CircularProgress size={24} /> : 'Create Farm'}
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
});

export default NoFarmPermissionsDialog;
